import React from "react";
import { useNavigate } from "react-router-dom";
import clearLogo from "./Imgs/clear-removebg.png";

export default function Restorehardware() {
  const navigate = useNavigate();

  return (
    <div className="fade-up-element flex min-h-[calc(100vh-80px)] flex-col items-center overflow-hidden">
      <div className="relative flex w-full items-center justify-center">
        <i className="mdi mdi-chevron-left absolute left-0 text-[30px]"></i>
        <h1 className="text-lg font-semibold text-[#414763]">Setup PIN</h1>
      </div>

      <div className="mt-10 flex w-full flex-1 flex-col items-center gap-4">
        <h1 className="text-sm font-semibold text-[#414763]">Enter your PIN</h1>

        <div className="flex items-center justify-center space-x-10 p-4">
          {["", "", "", ""].map((item, index) => (
            <div className="h-2 w-2 rounded-full bg-[#dde5f0]" key={index}>
              {item}
            </div>
          ))}
        </div>

        <h1 className="text-xs text-[#9ca3ad]">Switch to 6-digit PIN</h1>

        <div className="mt-auto flex h-[100%] w-full flex-1 flex-row flex-wrap justify-center px-[60px] py-[8]">
          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0"].map(
            (items, index) => (
              <div
                key={index}
                className="flex w-[30%] items-center justify-center px-0 py-[12px] text-xl font-bold text-[#414763]"
              >
                {items}
              </div>
            ),
          )}

          <button className="flex w-[30%] cursor-pointer items-center justify-center gap-4">
            <img className="h-[auto] w-[20px]" src={clearLogo} alt="center" />
          </button>
        </div>
      </div>
    </div>
  );
}
