import React from 'react'
import { useNavigate } from 'react-router-dom'
import clearLogo from './Imgs/clear-removebg.png'

export default function Restorehardware() {

  const navigate = useNavigate()

  return (
    <div className="flex fade-up-element flex-col h-[100vh] overflow-hidden items-center min-h-screen">
      <div className='relative flex items-center justify-center w-full'>
        <button onClick={() => navigate(-1)} className='absolute left-0'>
          <i className='mdi mdi-chevron-left text-[30px]'></i>
        </button>
        <h1 className='text-[#414763] text-lg font-semibold'>Setup PIN</h1>
      </div>


      <div className='mt-14 flex-1 flex flex-col w-full gap-4 items-center'>
        <h1 className='text-[#414763] text-sm font-semibold'>Enter your PIN</h1>

        <div className='flex justify-center items-center space-x-10 p-4'>
          {
            ["", "", "", ""].map((item, index) => (
              <div className='w-2 h-2 bg-[#dde5f0] rounded-full' key={index}>{item}</div>
            ))
          }
        </div>

        <h1 className='text-xs text-[#9ca3ad]'>Switch to 6-digit PIN</h1>

        <div className='flex flex-1 flex-row justify-center flex-wrap mt-auto py-[8] h-[100%] w-full px-[60px]'>
          {
            ["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0"].map((items, index) => (
              <div key={index} className='flex w-[30%] px-0 py-[12px] justify-center items-center text-xl text-[#414763] font-bold'>{items}</div>
            ))
          }

          <button className='flex w-[30%] justify-center items-center gap-4 cursor-pointer'>
            <img className='w-[20px] h-[auto]' src={clearLogo} alt='center' />
          </button>
        </div>
      </div>

    </div>
  )
}
