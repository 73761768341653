import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import mon from "../Imgs/monero.png";
import bit from "../Imgs/Bitcoin-removebg-preview.png";
import eth from "../Imgs/ethereum-removebg-preview.png";
import lite from "../Imgs/litecoin-removebg-preview.png";
import bch from "../Imgs/bitcoin-cash-removebg-preview.png";
import mat from "../Imgs/matic-removebg-preview.png";
import sol from "../Imgs/solana-removebg.png";
import trc from "../Imgs/tron-removeb.png";
import nano from "../Imgs/haven-removebg-preview.png";
import wow from "../Imgs/wonero-logo.png";
import xhv from "../Imgs/haven-logo.png";

// List of wallet currencies
const walletCurrencies = [
  { name: "Monero (XMR)", icon: mon },
  { name: "Bitcoin (BTC)", icon: bit },
  { name: "Ethereum (ETH)", icon: eth },
  { name: "Litecoin (LTC)", icon: lite },
  { name: "Bitcoin Cash (BCH)", icon: bch },
  { name: "Polygon (MATIC)", icon: mat },
  { name: "Solana (SOL)", icon: sol },
  { name: "Tron (TRX)", icon: trc },
  { name: "Nano (XNO)", icon: nano },
  { name: "Wownero (WOW)", icon: wow },
  { name: "Haven (XHV)", icon: xhv },
];

export default function RestoreWallet() {
  const navigate = useNavigate();

  // State to handle the search input and the selected wallet
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedWallet, setSelectedWallet] = useState(null); // Track selected wallet

  // Filtered currencies based on search query
  const filteredCurrencies = walletCurrencies.filter((wallet) =>
    wallet.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  // Handle wallet selection
  const handleWalletSelect = (wallet) => {
    sessionStorage.setItem("coin", wallet);
    setSelectedWallet(wallet);
  };

  return (
    <div className="fade-up-element flex min-h-[calc(100vh-80px)] flex-1 flex-col gap-7">
      <div className="relative flex w-full items-center justify-center">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <i className="mdi mdi-chevron-left text-[30px]"></i>
        </button>
        <h1 className="text-lg font-semibold text-[#414763]">Restore Wallet</h1>
      </div>

      {/* Search Bar */}
      <div className="mt-4 w-full">
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded-full border border-gray-300 bg-[#f2f4ff] p-2 pl-16 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {/* Search Icon */}
          <i className="mdi mdi-magnify absolute left-6 top-1/2 -translate-y-1/2 transform text-[20px] text-gray-500"></i>
        </div>
      </div>

      {/* Wallet List */}
      <div className="max-h-[60vh] w-full flex-1 overflow-y-auto">
        {filteredCurrencies?.length > 0 ? (
          filteredCurrencies?.map((wallet, index) => (
            <button
              key={index}
              className={`mb-4 flex w-full cursor-pointer items-center rounded-full px-8 py-4 ${selectedWallet === wallet.name ? "bg-[#4cb050] text-white" : "bg-[#f2f4ff] hover:bg-[#e0e0e0]"}`}
              onClick={() => handleWalletSelect(wallet.name)}
            >
              <img
                src={wallet.icon}
                alt={wallet.name}
                className="h-[24px] w-[24px]"
              />
              <span className="ml-4 text-left">{wallet.name}</span>
            </button>
          ))
        ) : (
          <p className="text-center text-gray-500">No wallets found</p>
        )}
      </div>

      {/* Next Button */}
      <div className="flex px-4 pb-2 pt-6">
        <button
          className={`flex w-full items-center justify-center rounded-full px-8 py-4 ${selectedWallet ? "cursor-pointer bg-[#815dfb]" : "cursor-not-allowed bg-gray-400"} text-center text-white`}
          onClick={() => {
            if (
              selectedWallet === "Monero (XMR)" ||
              selectedWallet === "Wownero (WOW)"
            ) {
              navigate("/import-with-seed-by-seed");
            } else if (
              selectedWallet === "Ethereum (ETH)" ||
              selectedWallet === "Polygon (MATIC)" ||
              selectedWallet === "Solana (SOL)" ||
              selectedWallet === "Tron (TRX)" ||
              selectedWallet === "Nano (XNO)" ||
              selectedWallet === "Haven (XHV)"
            ) {
              navigate("/import-by-seed");
            } else {
              navigate("/import-with-seed");
            }
          }}
          disabled={!selectedWallet}
        >
          Next
        </button>
      </div>
    </div>
  );
}
