import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import mon from "../Imgs/monero.png";
import bit from "../Imgs/Bitcoin-removebg-preview.png";
import eth from "../Imgs/ethereum-removebg-preview.png";
import lite from "../Imgs/litecoin-removebg-preview.png";
import bch from "../Imgs/bitcoin-cash-removebg-preview.png";
import mat from "../Imgs/matic-removebg-preview.png";
import sol from "../Imgs/solana-removebg.png";
import trc from "../Imgs/tron-removeb.png";
import nano from "../Imgs/haven-removebg-preview.png";
import wow from "../Imgs/wonero-logo.png";
import xhv from "../Imgs/haven-logo.png";

const walletCurrencies = [
  { name: "Monero (XMR)", icon: mon },
  { name: "Bitcoin (BTC)", icon: bit },
  { name: "Ethereum (ETH)", icon: eth },
  { name: "Litecoin (LTC)", icon: lite },
  { name: "Bitcoin Cash (BCH)", icon: bch },
  { name: "Polygon (MATIC)", icon: mat },
  { name: "Solana (SOL)", icon: sol },
  { name: "Tron (TRX)", icon: trc },
  { name: "Nano (XNO)", icon: nano },
  { name: "Wownero (WOW)", icon: wow },
  { name: "Haven (XHV)", icon: xhv },
];

export default function CreateWallet() {
  const navigate = useNavigate();

  // State to handle the search input and selected wallet
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedWallet, setSelectedWallet] = useState(null); // Track selected wallet

  // Filtered currencies based on search query
  const filteredCurrencies = walletCurrencies.filter((wallet) =>
    wallet.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  // Handle wallet selection
  const handleWalletSelect = (wallet) => {
    setSelectedWallet(wallet);
  };

  return (
    <div className="flex min-h-screen flex-1 flex-col gap-8">
      <div className="fade-up-element relative flex w-full items-center justify-center">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <i className="mdi mdi-chevron-left text-[30px]"></i>
        </button>
        <h1 className="text-lg font-semibold text-[#414763]">Create Wallet</h1>
      </div>

      <div className="flex flex-1 flex-col">
        <h1 className="mb-4 text-center font-bold text-[#414763]">
          Please choose wallet currency:
        </h1>

        <div className="mt-4 flex w-full">
          <div className="relative w-full px-4">
            {/* Input Field */}
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full rounded-full border border-gray-300 bg-[#f2f4ff] p-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {/* Search Icon */}
            <i className="mdi mdi-magnify absolute left-6 top-1/2 -translate-y-1/2 transform text-[20px] text-gray-500"></i>
          </div>
        </div>

        <div className="mt-6 flex h-[68vh] w-full flex-col gap-4 overflow-scroll px-4">
          {filteredCurrencies.length > 0 ? (
            filteredCurrencies.map((wallet, index) => (
              <button
                key={index}
                className={`flex w-full cursor-pointer items-center rounded-full px-8 py-4 ${selectedWallet === wallet.name ? "bg-[#4cb050] text-white" : "bg-[#f2f4ff] hover:bg-[#e0e0e0]"}`}
                onClick={() => handleWalletSelect(wallet.name)}
              >
                <img
                  src={wallet.icon}
                  alt={wallet.name}
                  className="h-[24px] w-[24px]"
                />
                <span className="ml-4 text-left">{wallet.name}</span>
              </button>
            ))
          ) : (
            <p className="text-center text-gray-500">No wallets found</p>
          )}
        </div>
      </div>

      <div className="flex px-4 pb-2 pt-2">
        <button
          className={`flex w-full items-center justify-center rounded-full px-8 py-4 ${selectedWallet ? "cursor-pointer bg-[#815dfb]" : "cursor-not-allowed bg-gray-400"} text-center text-white`}
          onClick={() => selectedWallet && navigate("/newwallet")} // Only navigate if a wallet is selected
          disabled={!selectedWallet} // Disable button if no wallet is selected
        >
          Next
        </button>
      </div>
    </div>
  );
}
