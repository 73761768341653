import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import clearLogo from "../Imgs/clear-removebg.png";

export default function Createpin() {
  const [firstPin, setFirstPin] = useState(""); // Track first PIN entry
  const [secondPin, setSecondPin] = useState(""); // Track second PIN entry
  const [step, setStep] = useState(1); // Step 1 for first PIN, Step 2 for confirming PIN
  const [isSixDigit, setIsSixDigit] = useState(false); // Track if the user is using a 6-digit PIN
  const [showSuccessModal, setShowSuccessModal] = useState(false); // Modal state for success
  const [showErrorModal, setShowErrorModal] = useState(false); // Modal state for error

  const navigate = useNavigate();

  const pinLength = isSixDigit ? 6 : 4; // Determine PIN length based on the state

  // Function to handle digit input
  const handlePinInput = (digit) => {
    if (step === 1 && firstPin.length < pinLength) {
      setFirstPin(firstPin + digit); // Append digit to first PIN
    } else if (step === 2 && secondPin.length < pinLength) {
      setSecondPin(secondPin + digit); // Append digit to second PIN
    }
  };

  // Function to clear the last digit
  const handleClear = () => {
    if (step === 1) {
      setFirstPin(firstPin.slice(0, -1));
    } else if (step === 2) {
      setSecondPin(secondPin.slice(0, -1));
    }
  };

  // Function to handle "OK" in the success modal
  const handleOkSuccess = () => {
    setShowSuccessModal(false);
    navigate("/createwallet"); // Navigate to the next page after success
  };

  // Function to handle "OK" in the error modal
  const handleOkError = () => {
    setShowErrorModal(false);
    setFirstPin(""); // Reset the PINs
    setSecondPin("");
    setStep(1); // Go back to the first step
  };

  // When both PINs are entered
  const handleNextStep = () => {
    if (step === 1 && firstPin.length === pinLength) {
      setStep(2); // Move to the second step to confirm PIN
    } else if (step === 2 && secondPin.length === pinLength) {
      if (firstPin === secondPin) {
        setShowSuccessModal(true); // Show success modal if PINs match
      } else {
        setShowErrorModal(true); // Show error modal if PINs don't match
      }
    }
  };

  return (
    <div className="fade-up-element flex min-h-[calc(100vh-80px)] flex-col items-center overflow-hidden">
      <div className="relative flex w-full items-center justify-center">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <i className="mdi mdi-chevron-left text-[30px]"></i>
        </button>
        <h1 className="text-lg font-semibold text-[#414763]">
          {step === 1 ? "Create PIN" : "Confirm PIN"}
        </h1>
      </div>

      <div className="mt-10 flex w-full flex-1 flex-col items-center gap-4">
        <h1 className="text-sm font-semibold text-[#414763]">
          {step === 1 ? "Enter your PIN" : "Re-enter your PIN"}
        </h1>

        <div className="flex items-center justify-center space-x-10 p-4">
          {Array(pinLength)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 ${step === 1 ? (firstPin.length > index ? "bg-[#414763]" : "bg-[#dde5f0]") : secondPin.length > index ? "bg-[#414763]" : "bg-[#dde5f0]"} rounded-full`}
              />
            ))}
        </div>

        {/* Toggle PIN length */}
        <h1
          className="cursor-pointer text-xs text-[#9ca3ad]"
          onClick={() => setIsSixDigit(!isSixDigit)}
        >
          {isSixDigit ? "Switch to 4-digit PIN" : "Switch to 6-digit PIN"}
        </h1>

        <div className="mt-auto flex h-[100%] w-full flex-1 flex-row flex-wrap justify-center px-[60px] py-[8]">
          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0"].map(
            (item, index) => (
              <button
                key={index}
                className="flex w-[30%] cursor-pointer items-center justify-center px-0 py-[12px] text-xl font-bold text-[#414763]"
                onClick={() => item !== "" && handlePinInput(item)}
              >
                {item}
              </button>
            ),
          )}

          <button
            className="flex w-[30%] cursor-pointer items-center justify-center gap-4"
            onClick={handleClear}
          >
            <img className="h-[auto] w-[20px]" src={clearLogo} alt="Clear" />
          </button>
        </div>

        <button
          className="mt-4 w-[250px] rounded-full bg-[#815dfb] px-8 py-4 text-white"
          onClick={handleNextStep}
        >
          {step === 1 ? "Next" : "Confirm"}
        </button>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[80%] max-w-md rounded-[50px] bg-white p-8">
            <h1 className="text-center text-xl font-semibold text-[#414763]">
              Setup PIN
            </h1>
            <p className="mt-4 text-center">
              Your PIN has been set up successfully
            </p>
            <hr className="my-4" />
            <div className="flex justify-center">
              <button
                className="rounded-full px-6 py-2 font-bold text-[#815dfb]"
                onClick={handleOkSuccess}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {showErrorModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[80%] max-w-md rounded-[50px] bg-white p-8">
            <h1 className="text-center text-xl font-semibold text-[#815cff]">
              Error
            </h1>
            <p className="mt-4 text-center">
              The PINs do not match. Please try again.
            </p>
            <hr className="my-4" />
            <div className="flex justify-center">
              <button
                className="rounded-full px-6 py-2 font-bold text-[#815cff]"
                onClick={handleOkError}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
