import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Restorebackup() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to show/hide the modal

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  // Function to handle import
  const handleImport = () => {
    if (!selectedFile) {
      setShowModal(true); // Show modal if no file is selected
    } else {
      // Handle the import logic here (e.g., file upload)
      console.log("Importing file:", selectedFile.name);
    }
  };

  // Function to close modal
  const closeModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  return (
    <div className="fade-up-element flex min-h-[calc(100vh-80px)] flex-col items-center justify-between bg-white p-4">
      {/* Header */}
      <div className="relative flex w-full items-center">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <i className="mdi mdi-chevron-left text-[30px]"></i>
        </button>
        <h1 className="mx-auto text-lg font-bold text-[#414763]">
          Restore from backup
        </h1>
      </div>

      {/* Password Input */}
      <div className="flex w-full flex-1 flex-col items-center justify-center">
        <input
          type="password"
          placeholder="Enter backup password here"
          className="h-10 w-full border-b border-gray-300 bg-transparent px-2 pr-10 text-lg text-[#7787a5] outline-none placeholder:text-base focus:border-blue-500 focus:ring-0"
        />
      </div>

      {/* Buttons */}
      <div className="mb-8 flex w-full flex-row gap-2">
        {/* Hidden File Input */}
        <input
          type="file"
          id="file-input"
          className="hidden"
          onChange={handleFileChange}
        />

        {/* Select Backup File Button */}
        <button
          className="flex flex-1 cursor-pointer items-center justify-center rounded-full bg-[#9e9e9e] py-3 text-center text-white"
          onClick={() => document.getElementById("file-input").click()}
        >
          Select backup file
        </button>

        {/* Import Button */}
        <button
          className={`flex flex-1 items-center justify-center rounded-full py-3 ${selectedFile ? "bg-[#815dfb]" : "bg-[#9e9e9e]"} cursor-pointer text-center text-white`}
          onClick={handleImport}
        >
          Import
        </button>
      </div>

      {selectedFile && (
        <p className="mt-2 text-sm text-gray-600">
          Selected file: {selectedFile.name}
        </p>
      )}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-80 rounded-[40px] bg-white p-6">
            <h1 className="text-center text-xl font-bold text-black">Error</h1>
            <p className="mt-4 text-center">
              Please select backup file and enter backup password.
            </p>
            <hr className="my-4" />
            <div className="flex justify-center">
              <button
                className="rounded-full px-6 py-2 text-[#815dfb]"
                onClick={closeModal}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
