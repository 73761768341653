import React from "react";
import Btn from "../components/Btn";
import { useNavigate } from "react-router-dom";
import headLogo from "../Imgs/head-logo.png";

function Home() {
  const navigate = useNavigate();

  return (
    <div className="fade-up-element flex flex-col items-center justify-center gap-40 p-5">
      <div className="flex flex-col">
        <img src={headLogo} alt="Center" className="mb-5 h-[300px] w-[300px]" />

        <p className="max-w-xl text-center text-sm text-[#aeb8c3]">
          Welcome to
          <span className="block text-2xl font-bold text-[#414763]">
            Cake Wallet
          </span>
          <span className="block font-semibold text-[#aeb8c3]">
            Keep your crypto safe, piece of cake
          </span>
        </p>
      </div>

      <div className="flex w-full flex-col items-center justify-center">
        <p className="text-xs text-[#aeb8c3]">
          Please make a selection below to create or recover your wallet.
        </p>

        <Btn
          val={
            <div className="flex items-center">
              <i className="mdi mdi-plus text-[20px] text-white"></i>
              <span className="ml-2 font-semibold">Create New Wallet</span>
            </div>
          }
          func={() => navigate("/createpin")}
        />

        <Btn
          
          val={
            <div className="flex items-center">
              <i className="mdi mdi-refresh text-[20px] text-black"></i>
              <span className="ml-2 font-semibold text-black">
                Restore Wallet
              </span>
            </div>
          }
          bg={"#f2f4ff"}
          func={() => navigate("/restore")}
        />
      </div>
    </div>
  );
}

export default Home;
