import React from 'react'
import walletname from './Imgs/wallet-name.png'
import seedLogo from './Imgs/seed-phrase.png'
import dropDown from './Imgs/drop-down.png'
import { useNavigate } from 'react-router-dom'

export default function Import() {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col items-center fade-up-element justify-center bg-white p-5 min-h-screen'>

            <h1 className='text-[#414763] font-bold'>Restore from keys</h1>

            <div className='w-full flex flex-col space-y-4 mt-4'>

                <div className='relative flex justify-center items-center p-4'>
                    <input
                        type="text"
                        placeholder="Wallet name"
                        className='w-full h-8 px-2 border-b border-gray-300 bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10' // Added pr-10 to provide space for the image
                    />
                    <img
                        src={walletname} // Replace with your actual image path
                        alt="Icon"
                        className='absolute right-4 top-1/2 transform -translate-y-1/2 w-8 h-8'
                    />
                </div>

                <div className='relative flex justify-center items-center p-4'>
                    <input
                        type="text"
                        placeholder="Address"
                        className='w-full h-8 px-2 border-b border-gray-300 bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10' // Added pr-10 to provide space for the image
                    />
                </div>

                <div className='relative flex justify-center items-center p-4'>
                    <input
                        type="text"
                        placeholder="View key (private)"
                        className='w-full text-black h-8 px-2 border-b border-gray-300 bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10' // Added pr-10 to provide space for the image
                    />
                </div>

                <div className='relative flex justify-center items-center p-4'>
                    <input
                        type="text"
                        placeholder="Spend key (private)"
                        className='w-full h-8 px-2 border-b border-gray-300 bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10' // Added pr-10 to provide space for the image
                    />
                </div>

                <div className='relative flex justify-center items-center p-4'>
                    <input
                        type="text"
                        placeholder="Restore from blockheight"
                        className='w-full h-8 px-2 border-b border-gray-300 bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10' // Added pr-10 to provide space for the image
                    />
                </div>

                <h1 className='text-black font-sm p-4 font-semibold'>or</h1>


                <div className='relative flex justify-center items-center p-4'>
                    <input
                        type="text"
                        placeholder="Restore from date"
                        className='w-full h-8 px-2 border-b border-gray-300 bg-transparent outline-none focus:border-blue-500 focus:ring-0 pr-10' // Added pr-10 to provide space for the image
                    />
                </div>

                <div className='text-gray-500 text-sm w-full mx-auto text-center'>Please enter a date a few days before you created this wallet. Or if you know the blockheight, please enter it instead</div>


                <div className='flex flex-1 mt-4 px-4'>
                    <button className='w-full font-bold flex mt-8 items-center justify-center rounded-full py-4 px-8 bg-[#815dfb] cursor-pointer text-white text-center' disabled onClick={() => { navigate("/import-with-seed") }}>Restore</button>
                </div>

                <button className='cursor-pointer mb-4'>Advanced Settings</button>

            </div>

        </div>
    )
}
