import React from "react";

export default function Btn({ val, func, bg }) {
  return (
    <button
      className={
        "mt-4 flex w-full items-center justify-center rounded-[30px] border border-transparent p-4 text-center text-white hover:bg-[#6a3db9] focus:outline-none focus:ring-2 focus:ring-[#6a3db9]"
      }
      onClick={func}
      style={{ background: bg || "#815dfb" }}
    >
      {val}
    </button>
  );
}
