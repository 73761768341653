import "./App.css";
import Home from "./pages/Home";
import Restore from "./pages/Restore";
import Create from "./pages/Create";
import RestoreWallet from "./pages/RestoreWallet";
import Import from "./pages/Import";
import Restkeys from "./Restkeys";
import Restorebackup from "./pages/Restorebackup";
import Restorehardware from "./Restorehardware";
import Scan from "./Scan";
import Createpin from "./pages/Createpin";
import CreateWallet from "./pages/Createwallet";
import Newwallet from "./pages/Newwallet";
import Import2 from "./Import2";
import Import3 from "./Import3";
import Import4 from "./pages/Import4";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useLayoutEffect, useEffect, useState } from "react";
import firstLogo from "./Imgs/f1.png";
import secondLogo from "./Imgs/f2.png";
import thirdLogo from "./Imgs/f3.png";
import squares from "./Imgs/squares-bg.png";
import logo from "./Imgs/logo.png"; // Add your logo here

// Wrapper to reset scroll on route change
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);
  return children;
};

const images = [
  { title: "f1", icon: thirdLogo },
  { title: "f2", icon: secondLogo },
  { title: "f3", icon: firstLogo },
];

function App() {
  return (
    <>
      <nav className="fixed inset-0 z-10 hidden h-[70px] items-center bg-white px-8 shadow-md md:flex">
        <img src={logo} alt="Logo" className="h-10" />
        <div className="ml-4 text-4xl font-bold text-black">Cakewallet</div>
      </nav>

      <section
        className={`relative flex min-h-screen w-full flex-col gap-24 overflow-hidden bg-[#fff] bg-fixed bg-[right_center] bg-no-repeat py-[10px] text-[16px] text-[#010101] max-md:![background-image:none] md:mt-[70px] md:flex-row`}
        style={{
          backgroundImage: `url(${squares})`,
          backgroundSize: "60%",
        }}
      >
        {/* Main App div remains in position, with padding added to avoid being covered by navbar */}
        <div className="w-full pl-[0] pt-[20px] shadow-lg md:w-[500px] md:pl-[5%] md:shadow-none">
          <BrowserRouter>
            <Wrapper>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/restore" element={<Restore />} />
                <Route path="/create" element={<Create />} />
                <Route path="/restorewallet" element={<RestoreWallet />} />
                <Route path="/import-with-seed" element={<Import />} />
                <Route path="/import-with-seed-by-seed" element={<Import2 />} />
                <Route path="/import-by-seed" element={<Import3 />} />
                <Route path="/import-with-seed-by-key" element={<Import4 />} />
                <Route path="/restkeys" element={<Restkeys />} />
                <Route path="/restorebackup" element={<Restorebackup />} />
                <Route path="/restorehard" element={<Restorehardware />} />
                <Route path="/scan" element={<Scan />} />
                <Route path="/createpin" element={<Createpin />} />
                <Route path="/createwallet" element={<CreateWallet />} />
                <Route path="/newwallet" element={<Newwallet />} />
              </Routes>
            </Wrapper>
          </BrowserRouter>
        </div>

        {/* Div for the tilting images and background */}
        <div className="relative ml-[10%] hidden flex-1 items-center justify-center pr-[5%] md:flex">
          {images.map((image, index) => (
            <img
              key={index}
              src={image.icon}
              alt={image.title}
              className={`absolute top-[100px] w-[250px] ${
                image.title === "f1" && "slide-rotate-left"
              } ${image.title === "f3" && "slide-rotate-right"}`}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default App;
