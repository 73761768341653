import React from "react";
import { useNavigate } from "react-router-dom";
import restoreseed from "../Imgs/restore-seed.png";
import restorebackup from "../Imgs/restore-backup.png";

const arr = [
  {
    title: "Restore from seed/keys",
    sub: "Get back your wallet from seed/keys that you've saved to a secure place",
    icon: restoreseed,
    nav: "/create",
  },
  {
    title: "Restore from backup",
    sub: "You can restore the whole Cake Wallet app from your back-up file",
    icon: restorebackup,
    nav: "/restorebackup",
  },
];

export default function Restore() {
  const navigate = useNavigate();
  return (
    <div className="fade-up-element flex w-full flex-col items-center justify-center gap-4">
      <div className="relative flex w-full items-center justify-center">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <i className="mdi mdi-chevron-left text-[30px]"></i>
        </button>
        <h1 className="text-lg font-semibold text-[#414763]">Restore Wallet</h1>
      </div>

      <div className="flex w-full flex-col items-center gap-4 p-4">
        {arr.map((item, index) => (
          <button
            key={index}
            className="flex w-full cursor-pointer items-start justify-center rounded-md border border-gray-400 bg-[#e9f1fc] px-5 py-4"
            onClick={() => navigate(item.nav)}
          >
            <img src={item.icon} alt="Seed" className="h-8 w-8" />
            <div className="ml-4">
              <h1 className="text-left text-lg font-[600] text-[#354466]">
                {item.title}
              </h1>
              <p className="text-left text-[#354466]">{item.sub}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
