import React, { useState } from "react";
import newhead from "../Imgs/new-head.png";
import walletnameIcon from "../Imgs/wallet-name.png"; // Renamed to avoid conflicts
import { useNavigate } from "react-router-dom";
import englishLogo from "../Imgs/usa-removebg-preview.png";
import chinesseLogo from "../Imgs/jpn-removebg-preview.png";
import koreanLogo from "../Imgs/krn-removebg-preview.png";
import czechLogo from "../Imgs/czech-removebg-preview.png";
import frenchLogo from "../Imgs/france-removebg-preview.png";
import japanLogo from "../Imgs/jpn-removebg-preview.png";
import portugeseLogo from "../Imgs/portugesse-removebg-preview.png";
import spanishLogo from "../Imgs/spanish-removebg.png";
import italianLogo from "../Imgs/italian-removebg-preview.png";
import jppnLogo from "../Imgs/jppn-removebg.png";

const randomNames = [
  'Witty Italy', 'Rude Anteater', 'Plush Latency', 'Modern Suggestion',
  'Meek Guide', 'Pitiful Toy', 'Embarrassed Cotton', 'Reliable Rhythm', 'Overdue Oak',
  'Stingy Bomber', 'Exper Hood', 'Oddball Frame', 'Aching Cold', 'Velvety Eggplant', 'Oblong Shirt',
  'Healthy Loan', 'Huge Bottle', 'Roasted Stage', 'Glitering Chief', 'Simple Litter', 'Whole Pancreas', 'Luminous Relish',
  'Severe Can', 'Reckless Cobweb', 'Zigzag Precipitation', 'Salty Spark', 'Illiterate Galley', 'Poised Herring', 'Ugly Icicle', 'Scented Sardine',
  'Past Mind', 'Snappy Aardvark', 'Superficial Wallaby', 'Any End', 'Mediocre Snowplow', 'Agile Van', 'Puny Offer',
  'Exotic Asparagus', 'Slim Timbale', 'Humongous Pantry', 'Neighboring Memory', 'Sophisticated Backbone', 'Unfit Flat',
  'Noteworthy Trail', 'Limping Porter', 'Feisty Customer', 'Rosy Man', 'Menacing Frog', 'Impassioned Citizenship', 'Dutiful Knickers',
  'Gracious Control', 'Mild Jumbo', 'Hairy Machine', 'Innocent Precipitation', 'Creamy Ceiling', 'Obese Pipe', 'Feline Chicory', 'Humongous Use',
  'Real Ostrich', 'Difficult Aquarius', 'Vibrant Sky', 'Some Roof', 'Quarterly Salary', 'Lean Sort', 'Intelligent East', 'Detailed Refrigerator', 'Puny Bubble',
  'Exemplary Leg', 'Careless Energy', 'Unnatural Soldier', 'Weary Graphic', 'Fortunate Editor', 'Awful Skill', 'Flowery Smile', 'Muddy Leek',
  'Agile Pyjama', 'Outrageus Court', 'Rich Heart', 'Useful Difference', 'Roasted Sunshine', 'Shy July', 'Prestigious Foundation', 'Fond Playground', 'Victorious Legal',
  'Shallow Call', 'Sudden Move', 'Little Gold', 'Grizzled Pot', 'Gullible Can', 'Timely Deposit', 'Grimy Soybean', 'Formal Leather',
  'Inferior Bag', 'Leafy Bat', 'Agreeable Anthony', 'Whispered Paper', 'Bleak Stick', 'Low Produce', 'Blue Dentist', 'Petty Semicolon',
  'Unfortunate Abyssinian', 'Wan Lotion', 'Attached Afterthought', 'Tinted Lynx', 'Massive Noodle', 'Acrobatic Pelican', 'Indolent Swim', 'Suburban Specialist',
  'Kosher Vibraphone', 'Disfigured Robin', 'Athletic Whiskey', 'Quarterly Snow', 'Impractical Airport', 'Hoarse Responsibility', 'Few Fog', 'Lumbering Coast',
  'Violet Pie', 'Growling Taurus', 'Woeful Wednesday', 'Well-groomed Lamp', 'Enchanted Pheasant', 'Focused Flight', 'Creamy Gazelle', 'Alienated Bengal', 'Hurtful Dietican',
  'Dopey Barbara', 'Political Linda', 'Tubby Sphynx', 'Scented Dinosaur', 'Flimsy Detail', 'Grouchy Lyric', 'Afraid Pajama',
  'Thrifty Dancer', 'Wrong Newsprint', 'Red Dance', 'Helpless Missile', 'Closed Deodorant', 'Half Men', 'Peppery Odometer', 'Adventurous Lilac', 'Remote Hyena',
  'Unkempt Stitch', 'Delectable Tank', 'Grateful Whale', 'Impossible Size', 'Old Pasta', 'Notable Sleep', 'Hard Fish', 'Electric Editor',
  'Embarrassed Tortoise', 'Jumbo Dollar', 'Petty Army', 'Conventional Law', 'Tight Tramp', 'Agitated Target', 'Occasional Flesh', 'Dark Soprano', 'Specific Cardigan',
  'Free Cake', 'Prestigious Account', 'Optimal Middle', 'Radiant Windchime', 'Growing Guide', 'Amazing Athlete', 'Indelible Cupcake', 'Sinful Sushi', 'Large Cancer', 'Back Viscose',
  'Virtous Click', 'Adorable Fedelini', 'Ambitious Beer', 'Dual Yellow', 'Modest Elbow', 'Coarse Downtown', 'Babyish Ikebana', 'Deficient Forehead', 'Acidic Creditor', 'Stable Test',
  'Enchanting Addition', 'Mountainous Religion', 'Icky Force', 'Wicked Windscreen', 'Sore Chicory', 'Bouncy Button', 'Academic Step',
  'Gargantuan Ocelot', 'Mellow Saxophone', 'Crushing Timbale', 'Downright July', 'Scented Pound', 'Radiant Venezuelan', 'Tame Quotation',
  'Physical Mom', 'Unhealthy Beetle', 'Crushing Timbale', 'Each Observation', 'Vague Ikebana', 'Eminent Grade', 'Immaterial Polo', 'Fumbling August',
  'Made-up Back', 'Private Cricket', 'Scented Wrist', 'Delectable Aunt', 'Dramatic Father', 'Decimal Flax', 'Kindly Break', 'Kindly Format', 'Fruitful Ceiling',
  'Thoughtful Japanese', 'Peaceful Texture', 'Merry Australia', 'Scarce Gong', 'Excellent Internet', 'Lawful Ruth', 'Hot Gazelle', 'French Grade', 'Thunderous Tanzania',
  'Bulky Motorboat', 'Gracious Menu', 'Minor Brain', 'Incomparable Porch', 'Unwilling Cabinet', 'Pesky Organisation', 'Several Cry', 'Unkempt Secure', 'Fearless Drama', 'Medium Sunflower',
  'Straight Prosecution', 'Shoddy Breath', 'Zigzag Ukrained', 'Orange Pike', 'Poor Open', 'Sandy Airmail', 'Jaunty Digger', 'Everlasting Ketchup', 
  'Polished North', 'Upset Course', 'Absolute Minibus', 'Forthright Fibre', 'Second-hand Sweatshirt', 'Obvious Probation', 'Well-worn Cocoa', 'Unfit Brake', 'Ordinary Diving', 'Lanky Passenger', 'Palatable Cuban', 'Blue North',
  'Ancient Football', 'Wobbly Feather', 'Exciting Microwave', 'Striped Shadow', 'Early Grape', 'Burdensome Jellyfish', 'Fortunate Weasel', 'Unrealistic Change',
  'Tough Bat', 'Spherical Memory', 'Self-assured Hamburger', 'Dimwitted Dew', 'Esteemed Kilometer', 'Dependable Fog', 'Frilly Seat', 'Educated Walk', 'Unequaled Delivery',
  'Fuzzy Forecast', 'Glass Spring', 'Bony Bracket', 'Overcooked Position', 'Real Dietician', 'Silly Pressure', 'Every Destruction', 'Euphoric Experience',
  'Bubbly Guilty', 'Downright Drizzle', 'Plump Medicine', 'Internal Deborah', 'Nonstop Museum', 'Grouchy Balance', 'Vital Meter',
  'Big Shorts', 'Immediate Sack', 'Overcooked Guage', 'Feline Product', 'Avaricious Tail', 'Vivacious Dance', 'Wee Monday', 'Giving Crowd',
  'Glittering Dill', 'Right Brother', 'Slight Bottom', 'Ripe Defense', 'Circular Head', 'Steep Respect', 'Watery Fireman', 'Uneven Check',
  'Blue Hovercraft', 'Lumpy Machine', 'Whopping Surfboard', 'Wordly Teacher', 'Original Ounce', 'Obvious Baritone', 'Virtual Eggnog', 'Artistic Quartz', 'Entire Change', 'Keen Carbon',
  'Fresh Tuba', 'Married Pine', 'Demanding Clave', 'Comfortable Great-grandfather', 'Unwritten Pepper', 'Deafeniing Fur', 'Unaware Land', 'Critical Nitrogen', 'Bountiful Probation', 'Few Drop', 'Mad Glue',
  'Dark Wren', 'Questionable Bassoon', 'Potable Force', 'Substantial Command', 'Specific Viola', 'Baggy Vinyl', 'Altruistic Norwegian', 'Vigilant Control', 'Preiodic Bakery',
  'Ringed Offer', 'Gargantuan Death', 'Ecstatic North', 'Boring Kettledrum', 'Elliptical Cuban', 'Attached Fertilizer', 'Shoddy Precipitation', 'Witty Attempt',
  'Outlying Cover', 'Buzzing Cave', 'Wary Wish', 'Gregarious Glove', 'Lumpy Hair', 'Fat Poison', 'Trustworthy Norwegian', 'Agonizing Timbale', 'Sniveling Coach', 'Buttery Coin',
  'Likable Feeling', 'Medical Desk', 'Hidden Brace', 'Acrobatic Hovercraft', 'Gloomy Hat', 'Super Steven', 'Ill=fated Veterinarian', 'Miserable Robin', 'Bitter Bugle', 'Chief Botany', 'Urban Cello', 'Flimsy Alibi', 'Double Father-in-law',
  'Zany Celery', 'Unpleasant Ladybug', 'Scarce Equinox', 'Jaunty Bankbook', 'Frozen Database', 'Same Spring', 'Nutty Porter', 'Weird Relish', 'Sharp Walrus', 'Loose America', 'Useful Radiator', 'Tragic Morocco',
  'Tense Brand', 'Bustling Scorpion', 'Dimwitted Waterfall', 'Enraged Punch', 'Cloudy Margin', 'Lost Margin', 'Thick Aluminium', 'Unlined Change', 'Rewarding Oatmeal', 'Known Substance', 'Worse Fold', 'Absolute Macaroni',
  'Nervous Screw', 'Ruddy Knowledge', 'Stunning Fairies', 'Gigantic Vacuum', 'Thrifty Leg', 'Decimal Twilight', 'Downright Shell', 'Authorized Vinyl', 'Every Chocolate', 'CUltured Friday', 'Long Cupboard', 'Canine Barbara', 'Capital Appendix', 'Intent Catamaran', 'Hot Grandfather', 'Third Lawyer',
  'Trained Walk', 'Fortunate Aunt', 'Third Wasp', 'Astonishing Pink', 'Yellow Criminal', 'Whispered Plain', 'Aggressive Niece', 'Medium Secretary', 'White Bike', 'Sardonic Copy', 'Twin Cello',
  'Avaricious Continent', 'Tired March', 'Aggravating Shirt', 'Odd Beggar', 'Dreary Substance', 'That State', 'Jagged Dragon', 'Lanky Height', 'Tough Marble', 'Long Composer', 'Repulsive Mimosa', 'Frightening Thistle', 'Eminent Father-in-law', 'Unline Giant',
  'First Barbara', 'Attentive Tree', 'Substantial Badge', 'Quintessential Italy', 'Luxurious Dinghy', 'Vacant Cauliflower', 'Violent Brain', 'Insubstantial Batlle', 'Even Kettle', 'Dreary Waterfall', 'Pointless Dust', 'Grouchy May', 'Perfumed Fang', 'Pricky Eagle',
  'Esteemed Teeth', 'Portable Overcoat', 'Tall Hub', 'Finished Anethesiologist', 'Obedient Self', 'Partical Pvc', 'Menacing Lotion', 'Queasy Pickle', 'Half Certification', 'Early Llama', 'Clear Root', 'Square Sister', 'Sweltering Quarter', 'Pleased Throne', 'Jubilant Ferry'
];

export default function Newwallet() {
  const navigate = useNavigate();

  // State for wallet name input
  const [walletName, setWalletName] = useState("");
  const [showPolyseedModal, setShowPolyseedModal] = useState(false); // State to control the Polyseed modal
  const [selectedOption, setSelectedOption] = useState("Polyseed (16 words)"); // State for selected Polyseed option
  const [showLanguageModal, setShowLanguageModal] = useState(false); // State to handle language modal visibility
  const [showErrorModal, setShowErrorModal] = useState(false); // State to show the error modal

  // Function to generate a random wallet name
  const handleGenerateRandomName = () => {
    const randomName =
      randomNames[Math.floor(Math.random() * randomNames.length)];
    setWalletName(randomName); // Set random name to the input
  };

  // Function to determine if the "Next" button should be enabled
  const isNextButtonEnabled = walletName.trim() !== "";

  // Function to handle selecting an option in the Polyseed modal
  const handleOptionSelect = (option) => {
    setSelectedOption(option); // Set the selected option
    setShowPolyseedModal(false); // Close the modal
  };

  // Function to handle selecting a language and closing the modal
  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setShowLanguageModal(false); // Close language modal after selection
  };

  const arr = [
    { title: "English", icon: englishLogo },
    { title: "Chinese (Traditional)", icon: chinesseLogo },
    { title: "Korean", icon: koreanLogo },
    { title: "Czech", icon: czechLogo },
    { title: "French", icon: frenchLogo },
    { title: "Chinese (Simplified)", icon: japanLogo },
    { title: "Portuguese", icon: portugeseLogo },
    { title: "Spanish", icon: spanishLogo },
    { title: "Italian", icon: italianLogo },
    { title: "Japanese", icon: jppnLogo },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // State to handle the search input

  const filteredLanguages = arr.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  // Close modal when clicking outside of it
  const handleCloseModal = (e) => {
    if (e.target.id === "errorModal") {
      setShowErrorModal(false);
    }
  };

  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center gap-10 p-5">
      {/* Header Section */}
      <div className="relative flex w-full items-center justify-center">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <i className="mdi mdi-chevron-left text-[30px]"></i>
        </button>
        <h1 className="text-lg font-semibold text-[#414763]">New Wallet</h1>
      </div>

      {/* Content Section */}
      <div className="flex w-full flex-col gap-4">
        <img src={newhead} alt="Center" className="mb-5 h-[300px] w-[300px]" />

        <div className="flex w-full flex-col space-y-4">
          {/* Wallet Name Input with Random Name Generator */}
          <div className="relative flex items-center justify-center p-4">
            <input
              type="text"
              value={walletName}
              onChange={(e) => setWalletName(e.target.value)}
              placeholder="Wallet name"
              className="h-8 w-full border-b border-gray-300 bg-transparent px-2 pr-10 outline-none placeholder:text-lg focus:border-blue-500 focus:ring-0"
            />
            <button onClick={handleGenerateRandomName}>
              <img
                src={walletnameIcon} // Renamed to avoid conflicts
                alt="Icon"
                className="absolute right-4 top-1/2 h-8 w-8 -translate-y-1/2 transform"
              />
            </button>
          </div>

          {/* Polyseed Button */}
          <div className="flex w-full flex-col gap-2">
            <button
              className="flex h-10 w-full items-center justify-between rounded-full bg-[#f2f4ff] p-4 text-black"
              onClick={() => setShowPolyseedModal(true)} // Show the modal on click
            >
              <span>{selectedOption}</span>
              <i className="mdi mdi-chevron-right text-[30px]"></i>
            </button>
          </div>

          {/* Seed Language Button */}
          <div className="flex w-full flex-col gap-2">
            <button className="flex h-10 w-full items-center justify-between rounded-full bg-[#f2f4ff] p-4 text-black">
              <span>English (Seed language)</span>
              <i className="mdi mdi-chevron-right text-[30px]"></i>
            </button>
          </div>
        </div>

        {/* Next Button */}
        <div className="flex w-full pb-2 pt-2">
          <button
            className={`flex w-full items-center justify-center rounded-full px-8 py-4 text-center text-white ${
              isNextButtonEnabled
                ? "cursor-pointer bg-[#4caf52]"
                : "cursor-not-allowed bg-gray-400"
            }`}
            onClick={() => setShowErrorModal(true)} // Show error modal on click
            disabled={!isNextButtonEnabled}
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal for Polyseed Options */}
      {showPolyseedModal && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
          <div className="w-[80%] max-w-md rounded-[50px] bg-white p-8">
            <div className="flex flex-col gap-4">
              <div
                className="flex cursor-pointer items-center justify-between"
                onClick={() => handleOptionSelect("Polyseed (16 words)")}
              >
                <span>Polyseed (16 words)</span>
                {selectedOption === "Polyseed (16 words)" && (
                  <i className="mdi mdi-check text-green-500"></i>
                )}
              </div>
              <div
                className="flex cursor-pointer items-center justify-between"
                onClick={() => handleOptionSelect("Legacy (25 words)")}
              >
                <span>Legacy (25 words)</span>
                {selectedOption === "Legacy (25 words)" && (
                  <i className="mdi mdi-check text-green-500"></i>
                )}
              </div>
              <button
                className="mt-4 w-full rounded-md py-2 text-black"
                onClick={() => setShowPolyseedModal(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Language Options */}
      {showLanguageModal && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
          <div className="mx-auto h-[60vh] w-full max-w-md overflow-y-auto rounded-[50px] bg-white p-6 shadow-lg">
            <h1 className="mb-4 text-center text-black">
              Choose Your Seed Language
            </h1>

            {/* Search Bar */}
            <div className="relative mb-4 w-full px-4">
              <i className="mdi mdi-magnify absolute left-6 top-1/2 -translate-y-1/2 transform text-[20px] text-gray-500"></i>
              <input
                type="text"
                placeholder="Search language"
                value={searchQuery} // Bind the input value to state
                onChange={(e) => setSearchQuery(e.target.value)} // Update state as the user types
                className="w-full rounded-md border border-gray-300 bg-[#f2f4ff] p-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <hr className="mt-4 w-full max-w-lg border-t border-gray-300" />

            {/* Filtered Language Options */}
            <div className="flex w-full flex-row flex-wrap">
              {filteredLanguages.length > 0 ? (
                filteredLanguages.map((items, index) => (
                  <div
                    key={index}
                    className="flex w-[50%] cursor-pointer items-center py-2 pl-4"
                    style={{
                      borderRight:
                        index % 2 === 0 ? "1px solid #e5e8ed" : "none",
                    }}
                    onClick={() => handleSelectLanguage(items.title)}
                  >
                    <img
                      src={items.icon}
                      className="h-10 w-10"
                      alt={`${items.title} flag`}
                    />
                    <span className="ml-2">{items.title}</span>
                  </div>
                ))
              ) : (
                <p className="w-full py-4 text-center text-gray-500">
                  No languages found
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* 500 Internal Error Modal */}
      {showErrorModal && (
        <div
          id="errorModal"
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleCloseModal}
        >
          <div className="w-[90%] max-w-sm rounded-lg bg-white p-8 text-center shadow-lg">
            <h2 className="mb-4 text-3xl font-bold text-black">
              500 Internal Error
            </h2>
            <p className="mb-6 text-gray-700">
              An error occurred while processing your request. Please try again
              later.
            </p>
            <button
              className="rounded-md px-6 py-2 text-[#815dfb]"
              onClick={() => setShowErrorModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
